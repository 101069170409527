<template>
  <div class="TdSearch_layout" v-if="isShow">
    <el-form v-if="SearchItem.isCustSearch || !isShowCustomize" class="SearchForm" label-position="right" :inline="true" label-width="100px" size="mini" @submit.native.prevent>
      <template v-for="(item,index) in SearchItem.searchList">
        <template v-if="item.isExpendShowSearch === '0'">
          <!-- 普通输入框 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'input'" :key="index + 'search'" :label="item.label" :title="item.title">
            <el-input style="width: 170px;" v-model="searchForm.inputLike[item.prop]" :placeholder="item.placeholder" clearable @keyup.enter.native="submitForm('')">
            </el-input>
          </el-form-item>
          <!-- 普通输入框 加宽-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'inputwiden'" :key="index + 'search'" :label="item.label" :title="item.title">
            <el-input style="width: 450px;" v-model="searchForm.inputLike[item.prop]" :placeholder="item.placeholder" clearable @keyup.enter.native="submitForm('')">
            </el-input>
          </el-form-item>
          <!-- 输入框 多单号查询-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'inputMore'" :key="index + 'search'" :label="item.label">
            <el-input style="width: 450px;" v-model="searchInputMore[item.prop]" :placeholder="item.placeholder" clearable @clear="clearInputValue(item, index)" @change="val => changeInputValue(val, item, index)">
              <el-button slot="append" icon="el-icon-plus" @click="inputMoreClick(item, index)"></el-button>
            </el-input>
          </el-form-item>
          <!-- 时间段选择框 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimerange'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 时间段选择框 限制范围不能超过一个月-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimelimit'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerLimitOptions" :unlink-panels="true" :clearable="false" :editable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 时间段选择框 限制范围不能超过三个月-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimethreelimit'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerThreeLimitOptions" :unlink-panels="true" :clearable="false" :editable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 时间段选择框 限制范围不能超过一年-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateOneYearLimit'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOneYearOptions" :unlink-panels="true" :clearable="false" :editable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 时间段选择框 默认4天-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimefourday'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 时间段选择框 默认2年-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimeTwoYear'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 时间段可清空选择框 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimeclear'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="true">
            </el-date-picker>
          </el-form-item>
          <!-- 月份段选择框 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'monthrange'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" format="yyyy年MM月" value-format="yyyy-MM" :unlink-panels="true" :clearable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 时间段默认一个月选择框 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimeMonthRange'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 月份选择框 带快捷-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateMonthRange'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" format="yyyy年MM月" value-format="yyyy-MM" :picker-options="pickerMothOptions" :unlink-panels="true">
            </el-date-picker>
          </el-form-item>
          <!-- 月份选择框 带快捷 默认当前月-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateMonthCurrent'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" format="yyyy年MM月" value-format="yyyy-MM" :picker-options="pickerMothOptions" :unlink-panels="true">
            </el-date-picker>
          </el-form-item>
          <!-- 月份选择框 单月-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateMonth'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 170px;" v-model="searchForm.dateList[item.prop]" type="month" format="yyyy年MM月" value-format="yyyy-MM" :clearable="false">
            </el-date-picker>
          </el-form-item>
          <!-- 年份选择框 默认当前月-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateYearCurrent'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-date-picker style="width: 170px;" v-model="searchForm.dateList[item.prop]" type="year" format="yyyy年" value-format="yyyy">
            </el-date-picker>
          </el-form-item>
          <!-- input带输入建议 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'inputurl'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-autocomplete style="width: 170px;" class="inline-input" v-model="searchForm.inputLike[item.prop]" value-key="lable" :fetch-suggestions="(queryString, cb)=>{inputQuerySearch(queryString, cb, index)}" :placeholder="item.placeholder" clearable>
            </el-autocomplete>
          </el-form-item>
          <!--项目名称 模糊查询&&精确查询-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'separeteSearch'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-forwarding-fuzzy style="width: 170px;" :v-model="separeteSearchForm" :placeholder="item.placeholder" clearable
                                 @change="handleChange" @clickSelect="clickSelect">
            </d2-forwarding-fuzzy>
          </el-form-item>
          <!--项目名称 模糊查询&&精确查询 停用的也能下拉显示-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'separeteSearchAll'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-forwarding-fuzzy-all ref="fuzzyAll" style="width: 170px;" :v-model="separeteSearchForm" :placeholder="item.placeholder" clearable
                                     @change="handleChange" @clickSelect="clickSelect">
            </d2-forwarding-fuzzy-all>
          </el-form-item>
          <!--查询立项报告里的项目名称 模糊查询&&精确查询-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'introProSearch'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-introduce-project-search style="width: 170px;" :v-model="separeteSearchForm" :placeholder="item.placeholder" clearable
                                         @change="handleChange" @clickSelect="clickSelect">
            </d2-introduce-project-search>
          </el-form-item>
          <!--查询客户漏斗里的项目名称 模糊查询&&精确查询-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'passengerProSearch'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-passenger-project-search style="width: 170px;" :v-model="separeteSearchForm" :placeholder="item.placeholder" clearable
                                         @change="handleChange" @clickSelect="clickSelect">
            </d2-passenger-project-search>
          </el-form-item>
          <!-- selectOption多选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectmoreopt'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- selectOption单选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectopt'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select ref="selectopt" style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- selectOption单多选 带描述解释-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectMoreDescription'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value">
                <span style="float: left;width: 100px;">{{ items.label }}</span>
                <span style="float: left; color: #8492a6; font-size: 12px;">{{ items.description }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- selectDict 字典多选-->
          <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectmoredict'"  :key="index + 'search'" :label="item.title" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in item.editOptions" :key="items.value" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- selectDict 字典单选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectdict'"  :key="index + 'search'" :label="item.title" :title="item.title">
            <el-select ref="selectdict" style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in item.editOptions" :key="items.value" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- selectUser 用户多选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectmoreuser'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in nextList" :key="items.value" :label="items.label" :value="items.username">
                <span style="float: left">{{ items.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px;margin-right:20px">{{ items.username }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- selectUser 用户单选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectuser'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in nextList" :key="items.value" :label="items.label" :value="items.username">
                <span style="float: left">{{ items.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px;margin-right:20px">{{ items.username }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- selectcustomer 发货单位单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectcustomer'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-forwarding style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-forwarding>
          </el-form-item>
          <!-- selectsupplier 收货单位单选 -->
          <!--        <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectsupplier'"  :key="index + 'search'" :label="item.label" :title="item.title">-->
          <!--          <d2-receiving style="width: 170px" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>-->
          <!--          </d2-receiving>-->
          <!--        </el-form-item>-->
          <!-- selectaddress 地址单选 始发地、目的地 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectaddress'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-cascader style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-cascader>
          </el-form-item>
          <!-- selectcarrier 承运单位单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectcarrier'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-bussup style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-bussup>
          </el-form-item>
          <!-- selectdept 法人公司单选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectcompany'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-company-moreselect ref="selectcompany" style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" :placeholder="item.placeholder" clearable>
            </d2-company-moreselect>
          </el-form-item>
          <!-- selectdept 法人公司多选 默认当前法人公司-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectmorecompany'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-company-default style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" :placeholder="item.placeholder" clearable>
            </d2-company-default>
          </el-form-item>
          <!-- selectdept 部门多选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectmoredept'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-dept-moreselect style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" :placeholder="item.placeholder" clearable>
            </d2-dept-moreselect>
          </el-form-item>
          <!-- selectdept 只有业务(项目)的部门 多选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectoperationdept'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-dept-operation style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" :placeholder="item.placeholder" clearable>
            </d2-dept-operation>
          </el-form-item>
          <!-- selectdept 部门多选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectOperationSettle'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-dept-operation-settle style="width: 170px;" v-model="searchForm.moreList[item.prop]" multiple :placeholder="item.placeholder" clearable>
            </d2-dept-operation-settle>
          </el-form-item>
          <!-- selectbank 银行账户多选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectbank'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-bank-moreselect style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" :placeholder="item.placeholder" clearable>
            </d2-bank-moreselect>
          </el-form-item>
          <!-- selectbuyer 购买方、销售方单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectbuyer'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-buyer style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-buyer>
          </el-form-item>
          <!-- selectsettleobj 结算对象单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectsettleobj'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-objinput-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-objinput-select>
          </el-form-item>
          <!-- selectSettleObjAll 结算对象单选 包含停用的-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSettleObjAll'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-objinput-selectAll style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-objinput-selectAll>
          </el-form-item>
          <!-- selectobjinput 结算对象单选 输入不会清空、支持模糊查询-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectobjinput'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-objinput style="width: 170px;" v-model="searchForm.inputLike[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-objinput>
          </el-form-item>
          <!-- selectUnitobj 结算单位单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectUnitobj'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-mdmselect style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-mdmselect>
          </el-form-item>
          <!-- 项目管理 是否启用 默认启用 单选-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectactive'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 中转单 单选 下拉选项为：全部，已投保，未投保；默认显示已投保 不可清空-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectFlagInsure'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" filterable remote reserve-keyword :placeholder="item.placeholder">
              <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 应收账单 单据小类多选 默认全选-->
          <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectsubdoctype'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in item.editOptions" :key="items.value" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 应收账单 结算单位id查询单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectReceiverId'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-receiverId style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" sourcetype="cus" clearable>
            </d2-receiverId>
          </el-form-item>
          <!-- 应收账单 项目名称id查询单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSenderId'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-senderId style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-senderId>
          </el-form-item>
          <!-- 异动单 收支类型查询单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectIncomeExpenditureTr'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-income-expenditure-search-tr style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-income-expenditure-search-tr>
          </el-form-item>
          <!-- 应收账单 收支大类查询单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectIncomeExpenditureYs'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-income-expenditure-search-ys style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-income-expenditure-search-ys>
          </el-form-item>
          <!-- 运价统计报表 统计类型查询条件默认为二级城市且不能清空-->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'statisticalType'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" :clearable="false">
              <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 客户漏斗 行业负责人 用户name单选-->
          <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectName'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" filterable reserve-keyword :placeholder="item.placeholder" clearable>
              <el-option v-for="items in marketInterface_options" :key="items.id" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!--用户name多选-->
          <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectMoreName'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in marketInterface_options" :key="items.id" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 客户漏斗 区域部门 多选-->
          <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectMoreAreaDept'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
              <el-option v-for="items in areaInterfaceDept_options" :key="items.id" :label="items.label" :value="items.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 运单总账报表 子项目下拉查询 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectChildProject'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-child-project-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-child-project-select>
          </el-form-item>
          <!-- 子项目成本单 子项目下拉查询 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSubprojectCost'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-child-subprojectCost-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-child-subprojectCost-select>
          </el-form-item>
          <!-- 子项目报价单 子项目下拉查询 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSubprojectQuotation'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-child-subprojectQuotation-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
            </d2-child-subprojectQuotation-select>
          </el-form-item>
          <!-- 物品设置 会计科目查询单选 -->
          <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSubject'"  :key="index + 'search'" :label="item.label" :title="item.title">
            <d2-inventory-subject style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable filterable>
            </d2-inventory-subject>
          </el-form-item>
        </template>
      </template>
      <el-divider v-if="isExpandBtnShow"></el-divider>
      <template v-if="isExpandShow">
        <template v-for="(item,index) in SearchItem.searchList">
          <template v-if="item.isExpendShowSearch === '1'">
            <!-- 普通输入框 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'input'" :key="index + 'search'" :label="item.label" :title="item.title">
              <el-input style="width: 170px;" v-model="searchForm.inputLike[item.prop]" :placeholder="item.placeholder" clearable @keyup.enter.native="submitForm('')">
              </el-input>
            </el-form-item>
            <!-- 普通输入框 加宽-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'inputwiden'" :key="index + 'search'" :label="item.label" :title="item.title">
              <el-input style="width: 450px;" v-model="searchForm.inputLike[item.prop]" :placeholder="item.placeholder" clearable @keyup.enter.native="submitForm('')">
              </el-input>
            </el-form-item>
            <!-- 输入框 多单号查询-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'inputMore'" :key="index + 'search'" :label="item.label">
              <el-input style="width: 450px;" v-model="searchInputMore[item.prop]" :placeholder="item.placeholder" clearable @clear="clearInputValue(item, index)" @change="val => changeInputValue(val, item, index)">
                <el-button slot="append" icon="el-icon-plus" @click="inputMoreClick(item, index)"></el-button>
              </el-input>
            </el-form-item>
            <!-- 时间段选择框 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimerange'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 时间段选择框 限制范围不能超过一个月-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimelimit'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerLimitOptions" :unlink-panels="true" :clearable="false" :editable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 时间段选择框 限制范围不能超过三个月-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimethreelimit'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerThreeLimitOptions" :unlink-panels="true" :clearable="false" :editable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 时间段选择框 限制范围不能超过一年-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateOneYearLimit'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOneYearOptions" :unlink-panels="true" :clearable="false" :editable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 时间段选择框 默认4天-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimefourday'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 时间段选择框 默认2年-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimeTwoYear'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 时间段可清空选择框 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimeclear'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="true">
              </el-date-picker>
            </el-form-item>
            <!-- 月份段选择框 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'monthrange'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" format="yyyy年MM月" value-format="yyyy-MM" :unlink-panels="true" :clearable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 时间段默认一个月选择框 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'datetimeMonthRange'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" :clearable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 月份选择框 带快捷-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateMonthRange'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" format="yyyy年MM月" value-format="yyyy-MM" :picker-options="pickerMothOptions" :unlink-panels="true">
              </el-date-picker>
            </el-form-item>
            <!-- 月份选择框 带快捷 默认当前月-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateMonthCurrent'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 450px;" v-model="searchForm.dateList[item.prop]" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" format="yyyy年MM月" value-format="yyyy-MM" :picker-options="pickerMothOptions" :unlink-panels="true">
              </el-date-picker>
            </el-form-item>
            <!-- 月份选择框 单月-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateMonth'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 170px;" v-model="searchForm.dateList[item.prop]" type="month" format="yyyy年MM月" value-format="yyyy-MM" :clearable="false">
              </el-date-picker>
            </el-form-item>
            <!-- 年份选择框 默认当前月-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'dateYearCurrent'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-date-picker style="width: 170px;" v-model="searchForm.dateList[item.prop]" type="year" format="yyyy年" value-format="yyyy">
              </el-date-picker>
            </el-form-item>
            <!-- input带输入建议 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'inputurl'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-autocomplete style="width: 170px;" class="inline-input" v-model="searchForm.inputLike[item.prop]" value-key="lable" :fetch-suggestions="(queryString, cb)=>{inputQuerySearch(queryString, cb, index)}" :placeholder="item.placeholder" clearable>
              </el-autocomplete>
            </el-form-item>
            <!--项目名称 模糊查询&&精确查询-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'separeteSearch'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-forwarding-fuzzy style="width: 170px;" :v-model="separeteSearchForm" :placeholder="item.placeholder" clearable
                                   @change="handleChange" @clickSelect="clickSelect">
              </d2-forwarding-fuzzy>
            </el-form-item>
            <!--项目名称 模糊查询&&精确查询 停用的也能下拉显示-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'separeteSearchAll'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-forwarding-fuzzy-all ref="fuzzyAll" style="width: 170px;" :v-model="separeteSearchForm" :placeholder="item.placeholder" clearable
                                       @change="handleChange" @clickSelect="clickSelect">
              </d2-forwarding-fuzzy-all>
            </el-form-item>
            <!--查询立项报告里的项目名称 模糊查询&&精确查询-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'introProSearch'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-introduce-project-search style="width: 170px;" :v-model="separeteSearchForm" :placeholder="item.placeholder" clearable
                                           @change="handleChange" @clickSelect="clickSelect">
              </d2-introduce-project-search>
            </el-form-item>
            <!--查询客户漏斗里的项目名称 模糊查询&&精确查询-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'passengerProSearch'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-passenger-project-search style="width: 170px;" :v-model="separeteSearchForm" :placeholder="item.placeholder" clearable
                                           @change="handleChange" @clickSelect="clickSelect">
              </d2-passenger-project-search>
            </el-form-item>
            <!-- selectOption多选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectmoreopt'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- selectOption单选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectopt'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select ref="selectopt" style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- selectOption单多选 带描述解释-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectMoreDescription'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value">
                  <span style="float: left;width: 100px;">{{ items.label }}</span>
                  <span style="float: left; color: #8492a6; font-size: 12px;">{{ items.description }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <!-- selectDict 字典多选-->
            <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectmoredict'"  :key="index + 'search'" :label="item.title" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in item.editOptions" :key="items.value" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- selectDict 字典单选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectdict'"  :key="index + 'search'" :label="item.title" :title="item.title">
              <el-select ref="selectdict" style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in item.editOptions" :key="items.value" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- selectUser 用户多选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectmoreuser'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in nextList" :key="items.value" :label="items.label" :value="items.username">
                  <span style="float: left">{{ items.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;margin-right:20px">{{ items.username }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <!-- selectUser 用户单选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectuser'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in nextList" :key="items.value" :label="items.label" :value="items.username">
                  <span style="float: left">{{ items.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;margin-right:20px">{{ items.username }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <!-- selectcustomer 发货单位单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectcustomer'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-forwarding style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-forwarding>
            </el-form-item>
            <!-- selectsupplier 收货单位单选 -->
            <!--        <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectsupplier'"  :key="index + 'search'" :label="item.label" :title="item.title">-->
            <!--          <d2-receiving style="width: 170px" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>-->
            <!--          </d2-receiving>-->
            <!--        </el-form-item>-->
            <!-- selectaddress 地址单选 始发地、目的地 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectaddress'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-cascader style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-cascader>
            </el-form-item>
            <!-- selectcarrier 承运单位单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectcarrier'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-bussup style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-bussup>
            </el-form-item>
            <!-- selectdept 法人公司单选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectcompany'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-company-moreselect ref="selectcompany" style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" :placeholder="item.placeholder" clearable>
              </d2-company-moreselect>
            </el-form-item>
            <!-- selectdept 法人公司多选 默认当前法人公司-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectmorecompany'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-company-default style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" :placeholder="item.placeholder" clearable>
              </d2-company-default>
            </el-form-item>
            <!-- selectdept 部门多选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectmoredept'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-dept-moreselect style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" :placeholder="item.placeholder" clearable>
              </d2-dept-moreselect>
            </el-form-item>
            <!-- selectdept 只有业务(项目)的部门 多选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectoperationdept'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-dept-operation style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" :placeholder="item.placeholder" clearable>
              </d2-dept-operation>
            </el-form-item>
            <!-- selectdept 部门多选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectOperationSettle'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-dept-operation-settle style="width: 170px;" v-model="searchForm.moreList[item.prop]" multiple :placeholder="item.placeholder" clearable>
              </d2-dept-operation-settle>
            </el-form-item>
            <!-- selectbank 银行账户多选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectbank'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-bank-moreselect style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" :placeholder="item.placeholder" clearable>
              </d2-bank-moreselect>
            </el-form-item>
            <!-- selectbuyer 购买方、销售方单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectbuyer'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-buyer style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-buyer>
            </el-form-item>
            <!-- selectsettleobj 结算对象单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectsettleobj'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-objinput-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-objinput-select>
            </el-form-item>
            <!-- selectSettleObjAll 结算对象单选 包含停用的-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSettleObjAll'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-objinput-selectAll style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-objinput-selectAll>
            </el-form-item>
            <!-- selectobjinput 结算对象单选 输入不会清空、支持模糊查询-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectobjinput'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-objinput style="width: 170px;" v-model="searchForm.inputLike[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-objinput>
            </el-form-item>
            <!-- selectUnitobj 结算单位单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectUnitobj'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-mdmselect style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-mdmselect>
            </el-form-item>
            <!-- 项目管理 是否启用 默认启用 单选-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectactive'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- 中转单 单选 下拉选项为：全部，已投保，未投保；默认显示已投保 不可清空-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectFlagInsure'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" filterable remote reserve-keyword :placeholder="item.placeholder">
                <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- 应收账单 单据小类多选 默认全选-->
            <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectsubdoctype'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in item.editOptions" :key="items.value" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- 应收账单 结算单位id查询单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectReceiverId'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-receiverId style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" sourcetype="cus" clearable>
              </d2-receiverId>
            </el-form-item>
            <!-- 应收账单 项目名称id查询单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSenderId'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-senderId style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-senderId>
            </el-form-item>
            <!-- 异动单 收支类型查询单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectIncomeExpenditureTr'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-income-expenditure-search-tr style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-income-expenditure-search-tr>
            </el-form-item>
            <!-- 应收账单 收支大类查询单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectIncomeExpenditureYs'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-income-expenditure-search-ys style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-income-expenditure-search-ys>
            </el-form-item>
            <!-- 运价统计报表 统计类型查询条件默认为二级城市且不能清空-->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'statisticalType'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :multiple="false" filterable remote reserve-keyword collapse-tags :placeholder="item.placeholder" :clearable="false">
                <el-option v-for="items in JSON.parse(item.editOptions)" :key="items.value" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- 客户漏斗 行业负责人 用户name单选-->
            <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectName'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" filterable reserve-keyword :placeholder="item.placeholder" clearable>
                <el-option v-for="items in marketInterface_options" :key="items.id" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!--用户name多选-->
            <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectMoreName'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in marketInterface_options" :key="items.id" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- 客户漏斗 区域部门 多选-->
            <el-form-item  v-if="item.isShowSearch === '0' && item.searchType === 'selectMoreAreaDept'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <el-select style="width: 170px;" v-model="searchForm.moreList[item.prop]" :multiple="true" filterable reserve-keyword collapse-tags :placeholder="item.placeholder" clearable>
                <el-option v-for="items in areaInterfaceDept_options" :key="items.id" :label="items.label" :value="items.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- 运单总账报表 子项目下拉查询 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectChildProject'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-child-project-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-child-project-select>
            </el-form-item>
            <!-- 子项目成本单 子项目下拉查询 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSubprojectCost'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-child-subprojectCost-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-child-subprojectCost-select>
            </el-form-item>
            <!-- 子项目报价单 子项目下拉查询 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSubprojectQuotation'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-child-subprojectQuotation-select style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable>
              </d2-child-subprojectQuotation-select>
            </el-form-item>
            <!-- 物品设置 会计科目查询单选 -->
            <el-form-item v-if="item.isShowSearch === '0' && item.searchType === 'selectSubject'"  :key="index + 'search'" :label="item.label" :title="item.title">
              <d2-inventory-subject style="width: 170px;" v-model="searchForm.oneList[item.prop]" :placeholder="item.placeholder" clearable filterable>
              </d2-inventory-subject>
            </el-form-item>
          </template>
        </template>
      </template>
      <el-form-item>
          <el-button style="margin-left: 22px;" :loading="loading" icon="el-icon-search" type="primary" @click="submitForm('')">{{loading ? '..' : '查询'}}</el-button>
          <el-button icon="el-icon-refresh" @click="resetForm('searchForm')">重置</el-button>
          <el-button v-if="isShowCustomize" icon="el-icon-setting" @click="onChangeCustomize" title="自定义查询界面">定义</el-button>
          <el-button v-if="isExpandBtnShow" :icon="isExpandShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="onclickExpand" title="展开更多查询条件">{{isExpandShow ? '折叠' : '展开'}}</el-button>
      </el-form-item>
    </el-form>
    <el-form v-else v-model="searchForm" class="SearchTdForm" :inline="true" size="mini">
          <el-form-item title="归属条件查询" v-if="nextList">
            <el-radio-group v-model="searchForm.operator" @change="branceChange">
              <el-radio-button :label="0">全部</el-radio-button>
              <el-radio-button :label="1">我的</el-radio-button>
              <el-radio-button :label="2">我组员</el-radio-button>
            </el-radio-group>
            <el-select v-if="searchForm.operator === 2" v-model="searchForm.moreList['createUser']" placeholder="我的组员" filterable multiple collapse-tags clearable style="width:210px;margin-left:5px;vertical-align:middle;">
              <el-option v-for="item in nextList" :key="item.value" :label="item.label" :value="item.username">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px;margin-right:20px">{{ item.username }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间" title="新增内容时间">
            <el-date-picker v-model="searchForm.dateList['createTime']" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true" style="width:350px"> <!--v-model="create_time"-->
            </el-date-picker>
          </el-form-item>
          <el-form-item title="腾大神级万能查询">
            <el-input placeholder="请输入查询内容" v-model="searchForm.inputLike[wnsearch]" class="input-with-select" clearable>
              <el-select v-model="wnsearch" slot="prepend" filterable clearable style="width:150px" placeholder="万能查询">
                <div v-for="(item,index) in SearchItem.searchList" :key="index">
                  <el-option v-if="item.isShowSearch === '0'"  :label="item.label" :value="item.prop" :title="item.title"></el-option>
                </div>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('')">查询</el-button>
            <el-button @click="resetForm('searchForm')">重置</el-button>
          </el-form-item>
        </el-form>
    <d2-container-query-setting v-if="dialogVisible.show" :settingCode="dialogVisible" @onSave="onSave" @closeClick="settingClose"></d2-container-query-setting>
    <el-dialog class="inputMoreStyle" :title="inputMoreTitle" v-if="inputMoreVisible" :visible.sync="inputMoreVisible" width="50%" :before-close="inputMoreClose" :destroy-on-close="true" append-to-body :close-on-click-modal="false">
      <el-alert :title="'多个' + inputMoreTitle + '信息，可用半角逗号(,)或者回车进行区分'" type="info" show-icon :closable="false"></el-alert>
      <el-input style="margin-top: 10px;" v-model="inputMoreTextarea" type="textarea" :rows="6" :placeholder="'请输入' + inputMoreTitle" clearable></el-input>
      <span slot="footer" class="dialog-footer">
              <el-button size="small" type="danger" @click="inputMoreClose">取 消</el-button>
              <el-button size="small" type="primary" @click="inputMoreSubmit">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>
<script>

import { getBranchUser } from '@/api/admin/user'
import { mapState } from 'vuex'
import { downValues } from '@/tdcommon/beeutil'
import { getSearchUser, dynamic, getDictUrl } from '@/api/sys.ui'
import { getdict } from '@/api/admin/dict'
import { validatenull } from '@/tdcommon/validate'
import dayjs from 'dayjs'
import { getDecodeStore, removeStore, setEncodeStore } from '@/tdcommon/store'
import { getAreaDept } from '@/api/admin/dept'

export default {
  name: 'd2-container-query',
  props: {
    SearchItem: {
      type: Object,
      default: () => {
        return {
          isCustSearch: false,
          searchList: []
        }
      }
    },
    ModularName: {
      type: String,
      default: () => ''
    },
    noinList: {
      type: Object,
      default: () => {}
    },
    dateList: {
      type: Object,
      default: () => {}
    },
    // 查询条件在 客户引入 显示
    isShowCustomer: {
      type: Boolean,
      default: () => false
    },
    // 查询条件在 供应商引入 显示
    isShowSupplier: {
      type: Boolean,
      default: () => false
    },
    // 查询条件在 发货单位 显示
    isShowForwarding: {
      type: Boolean,
      default: () => false
    },
    // 查询条件在 承运单位 显示
    isShowCarrier: {
      type: Boolean,
      default: () => false
    },
    // 查询条件在 车辆档案 显示
    isShowVehiclefile: {
      type: Boolean,
      default: () => false
    },
    // 是否显示自定义按钮
    isShowCustomize: {
      type: Boolean,
      default: () => true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    valueSet: {
      type: Object,
      default: () => {
        return { inputLike: {}, dateList: {}, moreList: {}, oneList: {} }
      }
    },
    // 是否显示折叠按钮
    isExpandBtnShow: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      dialogVisible: { title: '自定义查询', show: false, isSetting: false, sysCheck: '启用自定义查询界面', DataTransfer: [] },
      // 日期组件初始信息
      pickerOptions: {
        shortcuts: [{
          text: '昨天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '当天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime())
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近两个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 60)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近半年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 12)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      pickerMothOptions: {
        shortcuts: [{
          text: '本月',
          onClick (picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick (picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      pickerMinDate: '',
      pickerMaxDate: '',
      pickerYearDate: '',
      pickerLimitOptions: {
        shortcuts: [{
          text: '昨天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '当天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime())
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }],
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime()
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            const one = 30 * 24 * 3600 * 1000
            const minTime = this.pickerMinDate - one
            const maxTime = this.pickerMinDate + one
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
      pickerThreeLimitOptions: {
        shortcuts: [{
          text: '昨天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '当天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime())
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近两个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 60)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }],
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime()
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            const one = 90 * 24 * 3600 * 1000
            const minTime = this.pickerMinDate - one
            const maxTime = this.pickerMinDate + one
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
      pickerOneYearOptions: {
        shortcuts: [{
          text: '昨天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '当天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime())
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近两个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 60)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近半年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一年',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 12)
            picker.$emit('pick', [start, end])
          }
        }],
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerYearDate) {
            this.pickerYearDate = null
          } else if (minDate) {
            this.pickerYearDate = minDate
          }
        },
        disabledDate: (time) => {
          if (this.pickerYearDate) {
            let pickerDate = new Date(this.pickerYearDate)
            let startYear = pickerDate.getFullYear() - 1
            let endYear = pickerDate.getFullYear() + 1
            let startDate = startYear + '-' + (pickerDate.getMonth() + 1) + '-' + pickerDate.getDate()
            let endDate = endYear + '-' + (pickerDate.getMonth() + 1) + '-' + pickerDate.getDate()
            let minTime = new Date(startDate)
            let maxTime = new Date(endDate)
            return time.getTime() < minTime.getTime() || time.getTime() > maxTime.getTime()
          }
        }
      },
      // 下级用户信息
      nextList: [],
      isShow: true,
      isShowTime: false,
      wnsearch: '',
      firstLoad: true,
      lazyLoadTime: 900,
      searchForm: {
        inputLike: {},
        dateList: {},
        moreList: {},
        oneList: {},
        inputMore: {},
        noinList: this.noinList
      },
      changeValue: '',
      clickSelectVal: {},
      blurProp: '',
      separeteSearchForm: {},
      // 行业负责人
      marketInterface_options: [],
      // 区域接口部门
      areaInterfaceDept_options: [],
      // 单号多条查询
      inputMoreTextarea: '',
      inputMoreTitle: '',
      inputMoreIndex: null,
      inputMoreVisible: false,
      searchInputMore: {},
      // 是否显示折叠的查询条件
      isExpandShow: false
    }
  },
  computed: {
    ...mapState('d2admin/user', [
      'info'
    ])
  },
  created () {
    this.getSearchUI()
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // 区域接口部门
      getAreaDept().then(response => {
        let listItem = []
        response.data.forEach(item => {
          let option = {}
          option.label = item.label
          option.value = item.value.toString()
          option.id = item.id.toString()
          listItem.push(option)
        })
        this.areaInterfaceDept_options = listItem
      })
      // 获取 人员 下拉加载项
      getBranchUser(this.info.username).then(response => {
        let listItem = []
        response.data.forEach(item => {
          let option = {}
          option.label = item.name
          option.value = item.name
          option.id = item.id
          listItem.push(option)
        })
        this.marketInterface_options = listItem // 行业负责人
      })
    },
    firstSearchLoad (state, param) {
      this.isShow = false
      let searchForm = {
        inputLike: {},
        dateList: {},
        moreList: {},
        oneList: {},
        inputMore: {},
        noinList: this.noinList
      }
      if (state === 'khQuotation') { // 客户管理 跳转 报价单 给客户名称查询框赋值
        searchForm.inputLike = { entryName: param.customerName }
      }
      this.searchForm = searchForm
      this.isShow = true
    },
    // 第一次进入页面 不自动查询 需要给查询条件赋值
    firstDefaultData () {
      let _this = this
      if (_this.firstLoad === true) {
        setTimeout(function () {
          _this.firstDefaultForm()
        }, _this.lazyLoadTime)
      } else {
        _this.firstDefaultForm()
      }
    },
    initSearchData (data) {
      let _this = this
      if (_this.firstLoad === true) {
        setTimeout(function () {
          _this.submitForm(data)
        }, _this.lazyLoadTime)
      } else {
        _this.submitForm(data)
      }
    },
    // 取得查询条件界面参数
    getSearchUI () {
      this.isShow = false
      let searchForm = getDecodeStore({ name: 'se' + this.ModularName })
      if (validatenull(searchForm)) {
        getSearchUser(this.ModularName).then(response => {
          this.SearchItem.isCustSearch = response.data.isCustSearch
          this.SearchItem.searchList = response.data.searchList
          this.loadDefaultData(response.data.searchList)
          this.loadMonthDefaultData(response.data.searchList)
          this.loadMonthDefault(this.SearchItem.searchList)
          this.loadDefaultCompany(response.data.searchList)
          this.loadDefaultActive(response.data.searchList)
          this.loadAllSubDocType(response.data.searchList)
          this.getShowDownValues(0)
          this.searchForm = Object.assign(this.valueSet, this.searchForm)
          this.isShow = true
        }).catch(() => {
          this.isShow = true
        })
      } else {
        this.SearchItem.isCustSearch = searchForm.isCustSearch
        this.SearchItem.searchList = searchForm.searchList
        this.loadDefaultData(this.SearchItem.searchList)
        this.loadMonthDefaultData(this.SearchItem.searchList)
        this.loadMonthDefault(this.SearchItem.searchList)
        this.loadDefaultCompany(this.SearchItem.searchList)
        this.loadDefaultActive(this.SearchItem.searchList)
        this.loadAllSubDocType(this.SearchItem.searchList)
        this.searchForm = Object.assign(this.valueSet, this.searchForm)
        this.isShow = true
      }
      this.getBranchUser()
    },
    getRecentlyXDays () {
      let defaultDates = []
      defaultDates[0] = dayjs().add(-6, 'day').format('YYYY-MM-DD')
      defaultDates[1] = dayjs(new Date()).format('YYYY-MM-DD')
      return defaultDates
    },
    getRecentlyFourDays () {
      let defaultDates = []
      defaultDates[0] = dayjs().add(-3, 'day').format('YYYY-MM-DD')
      defaultDates[1] = dayjs(new Date()).format('YYYY-MM-DD')
      return defaultDates
    },
    getRecentlyTwoYear () {
      let defaultDates = []
      defaultDates[0] = dayjs().add(-2, 'year').format('YYYY-MM-DD')
      defaultDates[1] = dayjs(new Date()).format('YYYY-MM-DD')
      return defaultDates
    },
    getRecentlyXMonth () {
      let defaultDates = []
      defaultDates[0] = dayjs().add(-1, 'month').format('YYYY-MM')
      defaultDates[1] = dayjs().add(-1, 'month').format('YYYY-MM')
      return defaultDates
    },
    getCurrentMonth () {
      let defaultDates = []
      defaultDates[0] = dayjs().add(0, 'month').format('YYYY-MM')
      defaultDates[1] = dayjs().add(0, 'month').format('YYYY-MM')
      return defaultDates
    },
    getCurrentYear () {
      let defaultDates = ''
      defaultDates = dayjs().add(0, 'year').format('YYYY')
      return defaultDates
    },
    getXMonth () {
      let defaultDates = ''
      defaultDates = dayjs().add(0, 'month').format('YYYY-MM')
      return defaultDates
    },
    loadDefaultData (serverSearchList) {
      let searchFormTemp = this.copyDeepData(this.searchForm)
      if (serverSearchList && serverSearchList.length > 0) {
        for (var i = 0; i < serverSearchList.length; i++) {
          const searchModel = serverSearchList[i]
          if (searchModel.isShowSearch === '0' && (searchModel.searchType === 'datetimerange' || searchModel.searchType === 'datetimelimit' || searchModel.searchType === 'datetimethreelimit' || searchModel.searchType === 'dateOneYearLimit')) {
            searchFormTemp.dateList[searchModel.prop] = this.getRecentlyXDays()
            break
          }
          if (searchModel.isShowSearch === '0' && (searchModel.searchType === 'monthrange' || searchModel.searchType === 'dateMonthRange')) {
            searchFormTemp.dateList[searchModel.prop] = this.getRecentlyXMonth()
            break
          }
          if (searchModel.isShowSearch === '0' && searchModel.searchType === 'dateMonthCurrent') {
            searchFormTemp.dateList[searchModel.prop] = this.getCurrentMonth()
            break
          }
          if (searchModel.isShowSearch === '0' && searchModel.searchType === 'datetimefourday') {
            searchFormTemp.dateList[searchModel.prop] = this.getRecentlyFourDays()
            break
          }
          if (searchModel.isShowSearch === '0' && searchModel.searchType === 'datetimeTwoYear') {
            searchFormTemp.dateList[searchModel.prop] = this.getRecentlyTwoYear()
            break
          }
          if (searchModel.isShowSearch === '0' && searchModel.searchType === 'dateYearCurrent') {
            searchFormTemp.dateList[searchModel.prop] = this.getCurrentYear()
            break
          }
        }
        this.searchForm = searchFormTemp
      }
      this.firstLoad = false
    },
    getMonthRecentlyXDays () {
      let defaultDates = []
      defaultDates[0] = dayjs().add(-30, 'day').format('YYYY-MM-DD')
      defaultDates[1] = dayjs(new Date()).format('YYYY-MM-DD')
      return defaultDates
    },
    // 默认一个月查询
    loadMonthDefaultData (serverSearchList) {
      let searchFormTemp = this.copyDeepData(this.searchForm)
      if (serverSearchList && serverSearchList.length > 0) {
        for (var i = 0; i < serverSearchList.length; i++) {
          const searchModel = serverSearchList[i]
          if (searchModel.isShowSearch === '0' && searchModel.searchType === 'datetimeMonthRange') {
            searchFormTemp.dateList[searchModel.prop] = this.getMonthRecentlyXDays()
            break
          }
        }
        this.searchForm = searchFormTemp
      }
      this.firstLoad = false
    },
    loadMonthDefault (serverSearchList) {
      let searchFormTemp = this.copyDeepData(this.searchForm)
      if (serverSearchList && serverSearchList.length > 0) {
        for (var i = 0; i < serverSearchList.length; i++) {
          const searchModel = serverSearchList[i]
          if (searchModel.isShowSearch === '0' && (searchModel.searchType === 'dateMonth')) {
            searchFormTemp.dateList[searchModel.prop] = this.getXMonth()
            break
          }
        }
        this.searchForm = searchFormTemp
      }
      this.firstLoad = false
    },
    getAllSubDocType () {
      let allSubDocType = []
      allSubDocType = ['KH0101', 'KH0102', 'KH0104', 'FY0101', 'FY0102', 'SG0101', 'SG0102', 'SG0103']
      return allSubDocType
    },
    // 默认全选 所有单据小类 selectsubdoctype
    loadAllSubDocType (serverSearchList) {
      let searchFormTemp = this.copyDeepData(this.searchForm)
      if (serverSearchList && serverSearchList.length > 0) {
        for (var i = 0; i < serverSearchList.length; i++) {
          const searchModel = serverSearchList[i]
          if (searchModel.isShowSearch === '0' && searchModel.searchType === 'selectsubdoctype') {
            searchFormTemp.moreList[searchModel.prop] = this.getAllSubDocType()
            break
          }
        }
        this.searchForm = searchFormTemp
      }
      this.firstLoad = false
    },
    getDefaultCompany () {
      let defaultCompany = ''
      // defaultCompany = this.info.companyCode
      defaultCompany = ''
      return defaultCompany
    },
    // 默认当前法人公司 selectmorecompany
    loadDefaultCompany (serverSearchList) {
      let searchFormTemp = this.copyDeepData(this.searchForm)
      if (serverSearchList && serverSearchList.length > 0) {
        for (var i = 0; i < serverSearchList.length; i++) {
          const searchModel = serverSearchList[i]
          if (searchModel.isShowSearch === '0' && searchModel.searchType === 'selectmorecompany') {
            searchFormTemp.moreList[searchModel.prop] = this.getDefaultCompany()
            break
          }
        }
        this.searchForm = searchFormTemp
      }
      this.firstLoad = false
    },
    // 统计类型
    getDefaultStatisticalType () {
      let defaultActive = ''
      defaultActive = '1'
      return defaultActive
    },
    // // 统计类型 默认二级城市
    // loadDefaultStatisticalType (serverSearchList) {
    //   let searchFormTemp = this.copyDeepData(this.searchForm)
    //   if (serverSearchList && serverSearchList.length > 0) {
    //     for (var i = 0; i < serverSearchList.length; i++) {
    //       const searchModel = serverSearchList[i]
    //       if (searchModel.isShowSearch === '0' && searchModel.searchType === 'statisticalType') {
    //         searchFormTemp.oneList[searchModel.prop] = this.getDefaultStatisticalType()
    //         break
    //       }
    //     }
    //     this.searchForm = searchFormTemp
    //   }
    //   this.firstLoad = false
    // },
    // 默认启用 '0'
    getDefaultActive () {
      let defaultActive = ''
      defaultActive = '0'
      return defaultActive
    },
    // 是否启用 默认启用
    loadDefaultActive (serverSearchList) {
      let searchFormTemp = this.copyDeepData(this.searchForm)
      if (serverSearchList && serverSearchList.length > 0) {
        for (var i = 0; i < serverSearchList.length; i++) {
          const searchModel = serverSearchList[i]
          if (searchModel.isShowSearch === '0' && searchModel.searchType === 'selectactive') {
            searchFormTemp.oneList[searchModel.prop] = this.getDefaultActive()
            break
          }
          if (searchModel.isShowSearch === '0' && (searchModel.searchType === 'statisticalType' || searchModel.searchType === 'selectFlagInsure')) {
            searchFormTemp.oneList[searchModel.prop] = this.getDefaultStatisticalType()
            break
          }
        }
        this.searchForm = searchFormTemp
      }
      this.firstLoad = false
    },
    copyDeepData (data) {
      return JSON.parse(JSON.stringify(data))
    },
    // 整理下拉表信息
    getShowDownValues (index) {
      if (index === this.SearchItem.searchList.length) {
        setEncodeStore('se' + this.ModularName, this.SearchItem)
        this.isShow = true
        return
      }
      if (this.SearchItem.searchList[index].isShowSearch === '0' && !validatenull(this.SearchItem.searchList[index].dictType) && validatenull(this.SearchItem.searchList[index].dictUrl)) {
        getdict(this.SearchItem.searchList[index].dictType).then(response => {
          this.SearchItem.searchList[index].editOptions = response.data
          this.getShowDownValues(index + 1)
        }).catch(() => {
          this.getShowDownValues(index + 1)
        })
      } else if (this.SearchItem.searchList[index].isShowSearch === '0' && !validatenull(this.SearchItem.searchList[index].dictUrl) && validatenull(this.SearchItem.searchList[index].dictType)) {
        getDictUrl(this.SearchItem.searchList[index].dictUrl).then(response => {
          this.SearchItem.searchList[index].editOptions = response.data
          this.getShowDownValues(index + 1)
        }).catch(() => {
          this.getShowDownValues(index + 1)
        })
      } else {
        this.getShowDownValues(index + 1)
      }
    },
    // 获取用户下属信息
    getBranchUser () {
      let baranchUser = getDecodeStore({ name: 'BaranchUser' })
      if (validatenull(baranchUser)) {
        getBranchUser(this.info.username).then(response => {
          this.isShow = false
          let item = {}
          item.label = this.info.name
          item.username = this.info.username
          this.nextList.push(item)
          this.nextList = downValues(response.data)
          setEncodeStore('BaranchUser', this.nextList)
          this.isShow = true
        })
      } else {
        this.nextList = baranchUser
      }
    },
    // 远程查询得相关条件信息
    remoteMethod (query, index) {
      let option = this.SearchItem.searchList[index]
      dynamic(option.dynamicAddr, query).then(response => {
      })
    },
    // input带输入建议查询
    inputQuerySearch (queryString, cb, index, prop) {
      let option = this.SearchItem.searchList[index]
      dynamic(option.dynamicAddr, queryString).then(response => {
        cb(response.data)
      })
      cb(queryString)
    },
    clearInputValue (row, index) {
      this.inputMoreTextarea = ''
      this.inputMoreChange('', index)
    },
    changeInputValue (val, row, index) {
      this.inputMoreChange(val, index)
    },
    inputMoreClick (row, index) {
      this.inputMoreVisible = true
      this.inputMoreTextarea = ''
      this.inputMoreTitle = row.title
      this.inputMoreIndex = index
    },
    inputMoreClose () {
      this.inputMoreVisible = false
      this.inputMoreTextarea = ''
      this.inputMoreTitle = ''
      this.inputMoreIndex = null
    },
    inputMoreSubmit () {
      this.inputMoreChange(this.inputMoreTextarea, this.inputMoreIndex)
      this.inputMoreVisible = false
    },
    inputMoreChange (val, trIndex) {
      let value1 = val.replace(/\n/g, ',').replace(/\r/g, ',').replace(/\s/g, '')

      let separeteForm = {
        inputLike: {},
        dateList: {},
        moreList: {},
        oneList: {},
        inputMore: {},
        noinList: this.noinList
      }
      this.SearchItem.searchList.forEach((item, index) => {
        if (item.searchType === 'inputMore' && index === trIndex) {
          separeteForm.inputMore[item.prop] = value1.replace(/,$/, '')
          separeteForm.moreList[item.prop] = value1.split(',').filter(item => !validatenull(item))
          this.searchForm.inputMore[item.prop] = separeteForm.inputMore[item.prop]
          this.searchForm.moreList[item.prop] = separeteForm.moreList[item.prop]
          this.$set(this.searchInputMore, item.prop, value1.replace(/,$/, ''))
        }
      })
    },
    handleChange (val) {
      this.changeValue = ''
      this.changeValue = val
      this.changeSearch()
    },
    clickSelect (val) {
      this.clickSelectVal = ''
      this.clickSelectVal = val
      this.changeSearch()
    },
    changeSearch () {
      let separeteForm = {
        inputLike: {},
        dateList: {},
        moreList: {},
        oneList: {},
        inputMore: {},
        noinList: this.noinList
      }
      this.SearchItem.searchList.forEach((item, index) => {
        if (item.searchType === 'separeteSearch' || item.searchType === 'separeteSearchAll' || item.searchType === 'introProSearch' || item.searchType === 'passengerProSearch') {
          if (!validatenull(this.clickSelectVal)) {
            if ((this.changeValue === this.clickSelectVal.customerName) || ((item.searchType === 'introProSearch' || item.searchType === 'passengerProSearch') && this.changeValue === this.clickSelectVal.projectName)) {
              separeteForm.oneList[item.prop] = this.changeValue
              separeteForm.inputLike[item.prop] = ''
            } else {
              separeteForm.inputLike[item.prop] = this.changeValue
              separeteForm.oneList[item.prop] = ''
            }
          } else {
            separeteForm.inputLike[item.prop] = this.changeValue
            separeteForm.oneList[item.prop] = ''
          }
          this.separeteSearchForm = separeteForm
          this.searchForm.oneList[item.prop] = separeteForm.oneList[item.prop]
          this.searchForm.inputLike[item.prop] = separeteForm.inputLike[item.prop]
        }
      })
    },
    firstDefaultForm () {
      this.$emit('firstDefault', this.searchForm)
    },
    // 点击查询
    submitForm (data) {
      if (!validatenull(data) && !validatenull(data.companyCode)) {
        this.searchForm.oneList.companyCode = data.companyCode
      }
      this.$emit('searchClick', this.searchForm)
    },
    // 点击重置
    resetForm () {
      this.searchForm = {
        inputLike: {},
        dateList: this.searchForm.dateList,
        moreList: {},
        oneList: {},
        inputMore: {},
        noinList: this.noinList
      }
      this.resetEvent()
      this.$emit('searchClick', this.searchForm)
    },
    resetEvent () {
      this.isShow = false
      getSearchUser(this.ModularName).then(response => {
        this.SearchItem.isCustSearch = response.data.isCustSearch
        this.SearchItem.searchList = response.data.searchList
        this.loadDefaultData(response.data.searchList)
        this.loadMonthDefaultData(response.data.searchList)
        this.loadMonthDefault(this.SearchItem.searchList)
        this.loadDefaultCompany(response.data.searchList)
        this.loadDefaultActive(response.data.searchList)
        this.loadAllSubDocType(response.data.searchList)
        this.getShowDownValues(0)
        this.searchForm = Object.assign(this.valueSet, this.searchForm)
        this.isShow = true
      }).catch(() => {
        this.isShow = true
      })
      this.getBranchUser()
    },
    // 点击自定义查询条件按钮
    onChangeCustomize () {
      this.dialogVisible.isSetting = this.SearchItem.isCustSearch
      this.dialogVisible.DataTransfer = this.SearchItem.searchList
      this.dialogVisible.show = true
    },
    onclickExpand () {
      this.isExpandShow = !this.isExpandShow
    },
    settingClose () {
      this.dialogVisible.show = false
    },
    onSave (values) {
      this.dialogVisible.show = false
      this.SearchItem.searchList = []
      removeStore({ name: 'se' + this.ModularName, type: 'session' })
      removeStore({ name: 'BaranchUser', type: 'session' })
      this.getSearchUI()
    },
    branceChange (val) {
      if (val === 2 && this.nextList.length === 0) {
        this.getBranchUser()
      }
    }
  }
}
</script>

<style lang="scss">
.TdSearch_layout {
  .el-divider--horizontal {
    margin: 0 0 10px 0 !important;
  }
  // 搜索框 下外边距设置为16px
  .el-form-item--mini.el-form-item {
    margin-bottom: 10px;
  }
  // label 右内边距设置为8px
  .el-form-item__label {
    padding: 0 8px 0 0;
  }
  // label、日期框内的 至
  .el-form--inline .el-form-item__label,.el-range-editor--mini .el-range-separator {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #343434;
    letter-spacing: 1px;
  }
  // 输入内容框;日期内容框;查询、重置、定义按钮字体
  .el-input--mini .el-input__inner,.el-range-editor--mini .el-range-input,.el-button--mini {
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    letter-spacing: 1px;
  }
}
.inputMoreStyle {
  .el-dialog {
    border-radius: 10px;
    border: 1px solid #909399;
    box-shadow: 0 0 9px 3px #909399;
  }
  .el-dialog__header {
    padding: 12px 20px 12px 20px;
    border-bottom: 1px solid #F0F0F0;
  }
  .el-dialog__title{
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #343434;
    letter-spacing: 1px;
  }
  .el-icon-close:before {
    color: #343434;
  }
  .el-textarea__inner {
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    color: #343434;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-query/index.vue"
}
</vue-filename-injector>
