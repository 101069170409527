<template>
  <el-dialog width="60%" top="5vh" center title="选择地址" v-if="addressVisible" :visible.sync="addressVisible" :before-close="close" append-to-body :close-on-click-modal="false" destroy-on-close>
    <iframe id="mapPage" width="100%" height="600px" frameborder=0 :src="map_src">
    </iframe>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
export default {
  // 腾讯地图选择地址
  name: 'd2-container-tencent-location',
  data () {
    return {
      addressVisible: false,
      address: null, // 地址
      adcode: null,
      district: null,
      map_src: '',
      point: [] // 经纬度
    }
  },
  props: {
  },
  created () {
    let that = this
    window.addEventListener('message', function (event) {
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      var loc = event.data
      if (loc && loc.module === 'locationPicker') { // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        console.log('location', loc)
        that.point = [loc.latlng.lng, loc.latlng.lat]
        that.getAreaCode(loc.latlng.lat, loc.latlng.lng, loc.poiaddress)
      }
    }, false)
  },
  mounted () {
  },
  methods: {
    validateNull (val) {
      return validatenull(val)
    },
    show (point, address, adcode, district) {
      let mapUrl = 'https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=ZN5BZ-WMVLU-ZOCV5-GLL2K-F5R2Q-TOBUX&referer=TMS'
      if (point && address) {
        this.address = address
        this.point = point
        this.adcode = adcode
        this.district = district
        mapUrl += `&coord=${point[1]},${point[0]}`
        this.map_src = mapUrl
      } else {
        this.address = null
        this.point = []
        this.adcode = null
        this.district = null
        this.map_src = mapUrl
      }
      // 打开弹窗
      this.addressVisible = true
    },
    // 地址逆解析获取详细地址
    // 维度 经度
    getAreaCode (lat, lng, address) {
      let that = this
      // 这里可以直接this.$jsonp地址传入你的经纬度
      that.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?', {
        location: `${lat},${lng}`, // 经纬度
        key: 'ZN5BZ-WMVLU-ZOCV5-GLL2K-F5R2Q-TOBUX', // 这里就是要开启那个service不然会报错让你开启
        output: 'jsonp' // output必须jsonp   不然会超时
      }).then((res) => {
        // 获取到的res 就是继续的地址的所有信息
        console.log('地址逆解析获取res', res)
        if (res.status === 0) {
          that.address = address
          that.adcode = res.result.ad_info.adcode
          that.district = res.result.ad_info.province + res.result.ad_info.city + res.result.ad_info.district
        }
      })
    },
    close () {
      this.addressVisible = false
    },
    handleOk () {
      if (!validatenull(this.address)) {
        this.$emit('handleChange', { point: this.point, address: this.address, adcode: this.adcode, district: this.district })
        this.addressVisible = false
      } else {
        this.$notify({
          title: '提示',
          message: '请先选择地址！',
          duration: 2000,
          type: 'warning'
        })
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-tencent-location/index.vue"
}
</vue-filename-injector>
