<template>
  <div>
    <el-input placeholder="请输入" :ref="typeStr + 'Ref'" v-model="values" :title="values" size="mini" :clearable="clearable" :disabled="disabled" @change="inputChange">
      <el-button slot="append" icon="el-icon-location-outline" :disabled="disabled" @click="clickBtn"></el-button>
    </el-input>
    <el-dialog width="60%" top="5vh" center title="选择地址" v-if="addressVisible" :visible.sync="addressVisible" :before-close="close" append-to-body :close-on-click-modal="false" destroy-on-close>
      <iframe id="mapPage" width="100%" height="600px" frameborder=0 :src="map_src">
      </iframe>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="handleOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
export default {
  // 腾讯地图选择地址
  name: 'd2-container-tencent-location',
  data () {
    return {
      values: '',
      addressVisible: false,
      address: null, // 地址
      adcode: null,
      district: null,
      map_src: '',
      longitude: null, // 经度
      latitude: null, // 纬度
      point: [] // 经纬度
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    typeStr: {
      type: String,
      default: () => ''
    },
    lonStr: {
      type: String,
      default: () => ''
    },
    latStr: {
      type: String,
      default: () => ''
    },
    addressStr: {
      type: String,
      default: () => ''
    },
    codeStr: {
      type: String,
      default: () => ''
    },
    districtStr: {
      type: String,
      default: () => ''
    }
  },
  created () {
    let that = this
    window.addEventListener('message', function (event) {
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      var loc = event.data
      if (loc && loc.module === 'locationPicker') { // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        console.log('location', loc)
        that.point = [loc.latlng.lng, loc.latlng.lat]
        that.getAreaCode(loc.latlng.lat, loc.latlng.lng, loc.poiaddress)
      }
    }, false)
  },
  mounted () {
    this.getStartValues(this.value)
  },
  methods: {
    validateNull (val) {
      return validatenull(val)
    },
    // 选择项目、承运单位 或者 联系人 带出地址 调用方法
    callingMethod () {
      console.log('callingMethod')
      let address = this.$parent.elForm.model[this.typeStr]
      console.log('callingMethod-address', address)
      this.inputChange(address)
    },
    clickBtn () {
      console.log('typeStr', this.typeStr)
      console.log('addressStr', this.addressStr)
      let longitude = this.$parent.elForm.model[this.lonStr]
      let latitude = this.$parent.elForm.model[this.latStr]
      let address = this.$parent.elForm.model[this.typeStr]
      let adcode = this.$parent.elForm.model[this.codeStr]
      let district = this.$parent.elForm.model[this.districtStr]
      console.log('longitude', longitude)
      console.log('latitude', latitude)
      console.log('address', address)
      console.log('adcode', adcode)
      console.log('district', district)
      if (!validatenull(longitude)) {
        this.longitude = longitude
      } else {
        this.longitude = null
      }
      if (!validatenull(latitude)) {
        this.latitude = latitude
      } else {
        this.latitude = null
      }
      if (!validatenull(latitude) && !validatenull(latitude)) {
        this.point = [longitude, latitude]
      } else {
        this.point = []
      }
      if (!validatenull(address)) {
        this.address = address
      } else {
        this.address = null
      }
      if (!validatenull(adcode)) {
        this.adcode = adcode
      } else {
        this.adcode = null
      }
      if (!validatenull(district)) {
        this.district = district
      } else {
        this.district = null
      }
      this.$nextTick(() => {
        console.log('this.show')
        this.show(this.point, this.address)
      })
    },
    show (point, address, adcode, district) {
      console.log('point', point)
      console.log('address', address)
      let mapUrl = 'https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=ZN5BZ-WMVLU-ZOCV5-GLL2K-F5R2Q-TOBUX&referer=TMS'
      if (!validatenull(point) && !validatenull(address)) {
        console.log('AAAAAA')
        // this.address = address
        // this.point = point
        // this.adcode = adcode
        // this.district = district
        mapUrl += `&coord=${point[1]},${point[0]}`
        this.map_src = mapUrl
      } else {
        console.log('BBBBBB')
        // this.address = null
        // this.point = []
        // this.adcode = null
        // this.district = null
        this.map_src = mapUrl
      }
      // 打开弹窗
      this.addressVisible = true
    },
    // 地址逆解析获取详细地址
    // 维度 经度
    getAreaCode (lat, lng, address) {
      let that = this
      // 这里可以直接this.$jsonp地址传入你的经纬度
      that.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?', {
        location: `${lat},${lng}`, // 经纬度
        key: 'ZN5BZ-WMVLU-ZOCV5-GLL2K-F5R2Q-TOBUX', // 这里就是要开启那个service不然会报错让你开启
        output: 'jsonp' // output必须jsonp   不然会超时
      }).then((res) => {
        // 获取到的res 就是继续的地址的所有信息
        console.log('地址逆解析获取res', res)
        if (res.status === 0) {
          let province = res.result.ad_info.province
          let city = res.result.ad_info.city
          let district = ''
          let adcode = res.result.ad_info.adcode
          console.log('province', province)
          console.log('city', city)
          console.log('district', district)
          console.log('adcode', adcode)
          let provinceCode = adcode.slice(0, 2) + '0000'
          let cityCode = adcode.slice(0, 4) + '00'
          let districtCode = ''
          console.log('provinceCode', provinceCode)
          console.log('cityCode', cityCode)
          if (city === '东莞市' || city === '中山市' || city === '儋州市' || city === '嘉峪关市') { // 其下无区县级，三级取town
            districtCode = res.result.address_reference.town.id
            district = res.result.address_reference.town.title
          } else if (city === '北京市' || city === '天津市' || city === '上海市' || city === '重庆市') { // 二级设置为直辖市
            city = '市辖区'
            districtCode = adcode
            district = res.result.ad_info.district
          } else {
            districtCode = adcode
            district = res.result.ad_info.district
          }
          let addressNameList = [province, city, district]
          let addressCodeList = [provinceCode, cityCode, districtCode]
          console.log('addressNameList', addressNameList)
          console.log('addressCodeList', addressCodeList)

          let addressName = addressNameList.join('-')
          let addressCode = addressCodeList.join(',')

          console.log('address', address)
          console.log('addressName', addressName)
          console.log('addressCode', addressCode)
          that.address = address
          that.adcode = addressCode
          that.district = addressName
        }
      })
    },
    close () {
      this.addressVisible = false
    },
    inputChange (val) {
      let that = this
      // 这里可以直接this.$jsonp地址传入你的地址
      that.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?', {
        address: val, // 地址
        key: 'ZN5BZ-WMVLU-ZOCV5-GLL2K-F5R2Q-TOBUX', // 这里就是要开启那个service不然会报错让你开启
        output: 'jsonp' // output必须jsonp   不然会超时
      }).then((res) => {
        // 获取到的res 就是继续的地址的所有信息
        console.log('地址解析获取res', res)
        if (res.status === 0) {
          console.log('res.result', res.result)
          let location = res.result.location
          if (!validatenull(location)) {
            this.point = [location.lng, location.lat]
            this.inputOK(this.point, val)
          } else {
            this.point = []
            this.inputOK(this.point, val)
          }
        } else {
          console.log('res.status !== 0：', res.status)
          this.inputOK([], val)
        }
      })
    },
    inputOK (point, address) {
      this.$emit('change', { type: 'input', point: point, address: address, adcode: null, district: null })
    },
    handleOk () {
      if (!validatenull(this.address)) {
        this.$emit('change', { type: 'select', point: this.point, address: this.address, adcode: this.adcode, district: this.district })
        this.addressVisible = false
      } else {
        this.$notify({
          title: '提示',
          message: '请先选择地址！',
          duration: 2000,
          type: 'warning'
        })
      }
    },
    getStartValues (val) {
      console.log('val', val)
      if (!validatenull(this.typeStr)) {
        let model = this.$parent.elForm.model[this.typeStr]
        console.log('model', model)
        if (!validatenull(model)) {
          this.values = model
        } else {
          this.values = ''
        }
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      console.log('values', val)
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      console.log('value', val)
      // this.values = val
      this.getStartValues(this.value)
    }
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-tencent-address/index.vue"
}
</vue-filename-injector>
