<template>
  <div style="display: inline;font-size: 14px">
<!--    <el-button size="mini" :type="firstButon.icon === 'el-icon-success' || firstButon.icon === 'el-icon-error' ? 'danger' : 'success'" :underline="false" :icon="firstButon.icon" style="margin-left: 10px;margin-right: 10px;" @click="handleCommand(firstButon.index)">{{firstButon.name}}-->
<!--    </el-button>-->
    <el-dropdown class="td_dropdown" trigger="click" :size="sizeType" :disabled="disabled" split-button :type="typeColor" @click="handleCommand(firstButon.index)" @command="handleCommand">
      {{firstButon.name}}
<!--      <span class="el-dropdown-link">-->
<!--        <i class="el-icon-arrow-down"></i>-->
<!--      </span>-->
      <el-dropdown-menu slot="dropdown">
        <div v-for="(item,index) in moreButton" :key="index">
          <el-dropdown-item style="color: #F56C6C;padding: 0 0;margin: 0 15px 0 15px" :icon="item.icon" :command="item.index" :title="item.title" v-if="item.check" >{{item.name}}</el-dropdown-item>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-more-button',
  computed: {
    ...mapGetters(['permissions'])
  },
  props: {
    moreButton: {
      type: Array,
      default: function () {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      firstButon: {},
      sizeType: 'mini',
      typeColor: 'primary'
    }
  },
  methods: {
    handleCommand (command) {
      // this.moreButton.forEach((item, index) => {
      //   item.check = true
      //   item.index = index
      // })
      // this.firstButon = this.moreButton[command]
      // this.moreButton[command].check = false
      this.$emit('onClick', this.moreButton[command])
    },
    createFirstMenu () {
      this.moreButton.forEach((item, index) => {
        item.index = index
      })
      this.firstButon = this.moreButton[0]
      this.sizeType = validatenull(this.moreButton[0].size) ? 'mini' : this.moreButton[0].size
      this.typeColor = validatenull(this.moreButton[0].typeColor) ? 'primary' : this.moreButton[0].typeColor
      this.moreButton[0].check = false
    }
  },
  created () {
    this.createFirstMenu()
  }
}
</script>

<style lang="scss">
.el-icon-arrow-down:hover {
  color:#409EFF;
}
.td_dropdown .el-button {
  height: 28.5px;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-more-button/index.vue"
}
</vue-filename-injector>
