<template class="customer_select_style">
  <div>
    <el-input v-model="values" :title="values" :placeholder="placeholder" readonly size="mini" :disabled="disabled">
      <el-button slot="append" icon="el-icon-search" @click="handleClick" title="选择项目名称" :disabled="disabled"></el-button>
    </el-input>
    <el-dialog title="选择项目名称" width="1100px" :visible.sync="dialogFormVisible" append-to-body :close-on-click-modal="false">
      <d2-container>
        <template slot="header">
          <d2-container-query ref="queryBox" :loading="loading" ModularName='customerSelect' v-if="isShowSearch" :noinList="noinLists" :isShowCustomize="false" @searchClick="searchClick"/>
        </template>
        <template slot="body_td">
          <d2-data ref="td_table" ModularName='customerSelect' :table_style="tableStyle" :Data="tableData" :width="55" :minWidth="55" :loading="loading" :isSelection="true" :isIndex="true" :isPage="false" :page_count="page.currentPage" :page_size="page.pageSize" :page_amount="page.amount" :page_total="page.total"
                   @rowSelected="rowSelected" @rowDoubleClicked="rowDoubleClicked"></d2-data>
        </template>
        <template slot="footer">
          <el-row style="margin-top: 10px;">
            <el-col :span="19"><d2-container-pagination isPagination="left" :total="page.total" :page_count="page.currentPage" :page_size="page.pageSize" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></d2-container-pagination></el-col>
            <el-col :span="5">
              <el-button type="danger" size="mini" @click="dialogFormVisible = false">取消</el-button>
              <el-button type="danger" size="mini" :loading="false" @click="clearForm('clear')">清除</el-button>
              <el-button type="primary" size="mini" :loading="false" @click="submitForm('define')">确定</el-button>
            </el-col>
          </el-row>
        </template>
      </d2-container>
    </el-dialog>
  </div>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { customerSelect } from '@/api/basic/customer'
// 项目名称选择弹框

export default {
  name: 'd2-container-customer-select',
  data () {
    return {
      values: '',
      dialogFormVisible: false,
      // 表格加载处理
      loading: false,
      // 表格数据源
      tableData: [],
      tableStyle: 'width: 100%;height: 400px',
      // 是显示查询条件
      isShowSearch: true,
      // 已选择条数
      checkNum: 0,
      // 已选择内容
      checkSelect: [],
      // 分页信息
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 50, // 每页显示多少条
        descs: []
      },
      // 查询条件表单
      searchForm: {},
      noinLists: this.noids
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    leftSpan: {
      type: Number,
      default: () => 14
    },
    rightSpan: {
      type: Number,
      default: () => 6
    },
    mdmStr: {
      type: String,
      default: () => ''
    },
    marginLeft: {
      type: String,
      default: () => '8px'
    },
    noids: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    // 新表格打勾触发事件
    rowSelected (val) {
      this.checkSelect = this.$refs.td_table.getSelectedRows()
      this.checkNum = this.$refs.td_table.getSelectedRows().length
    },
    // 点击搜索查询点击
    searchClick (searchFrom) {
      this.searchForm = searchFrom
      // if (validatenull(this.searchForm.oneList.companyCode)) {
      //   this.tableData = []
      //   this.$message.error('请选择法人公司查询！')
      //   return
      // }
      this.fetchData()
    },
    // 查询接口方法
    fetchData () {
      this.loading = true
      customerSelect(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize,
        descs: this.page.descs
      }, this.searchForm)).then(response => {
        this.tableData = response.data.records
        this.page.total = response.data.total
        this.page.pageSize = response.data.size
        this.page.currentPage = response.data.current
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 清除
    clearForm (type) {
      this.$emit('change', { type: type, value: '' })
      this.dialogFormVisible = false
    },
    // 点击确定
    submitForm (type) {
      if (this.checkNum > 0) {
        if (this.checkNum === 1) {
          this.$emit('change', { type: type, value: this.checkSelect[0] })
          this.dialogFormVisible = false
        } else {
          this.$message.error('只能选择单条！')
        }
      } else {
        this.$message.error('不能为空！')
      }
    },
    // 双击行
    rowDoubleClicked (params) {
      this.$emit('change', { type: 'define', value: params.data })
      this.dialogFormVisible = false
    },
    // 修改分页数量
    handleSizeChange (size) {
      this.page.pageSize = size
      this.fetchData()
    },
    // 修改当前页码
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.fetchData()
    },
    handleClick () {
      // this.$emit('change', type)
      this.dialogFormVisible = true
      // this.$nextTick(() => {
      //   this.$refs.queryBox.initSearchData()
      // })
    },
    getStartValues (val) {
      console.log('customer-val', val)
      if (!validatenull(this.mdmStr)) {
        let model = this.$parent.elForm.model[this.mdmStr]
        console.log('customer-model', model)
        if (!validatenull(model)) {
          this.values = model
        } else {
          this.values = ''
        }
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      console.log('val', val)
      this.values = val
    }
  },
  mounted () {
    this.getStartValues(this.value)
  }
}
</script>

<style lang="scss">
.customer_select_style {
  .el-form-item__error {
    left: 40% !important;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-customer-select/index.vue"
}
</vue-filename-injector>
