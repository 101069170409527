<template>
  <div class="tencent-map-container">
    <div id="tencentMap" :style="{width:'100%',height:'680px'}"></div>
    <div class="toolbar">
        <span v-if="loaded">
          <el-tag size="" type="danger">经纬度：</el-tag>
          <el-tag size="mini" type="warning" class="mr-l-5">经度</el-tag><span style="font-size: 14px;"> {{ lng }}</span>
          <el-tag size="mini" type="success" class="mr-l-5">纬度</el-tag><span style="font-size: 14px;"> {{ lat }}</span>
        </span>
        <div v-if="loadedTrack">
          <div v-for="(item, index) in markers" :key="index">
            <el-tag size="" type="danger">{{index === 0 ? '起点：' : '终点：'}}</el-tag>
            <el-tag size="mini" type="info" class="mr-l-5">时间</el-tag><span style="font-size: 14px;"> {{ item.time }}</span>
            <el-tag size="mini" type="warning" class="mr-l-5">经度</el-tag><span style="font-size: 14px;"> {{ item.lon }}</span>
            <el-tag size="mini" type="success" class="mr-l-5">纬度</el-tag><span style="font-size: 14px;"> {{ item.lat }}</span>
          </div>
      </div>
      <span v-else></span>
    </div>
  </div>
</template>
<script>

import { getGpsPosition, getGpsTrack } from '@/api/basic/carinfo'
// import QQMap from 'qqmap'
// import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
import { validatenull } from '@/tdcommon/validate'
// let amapManager = new AMapManager() // 新建生成地图画布
export default {
  name: 'd2-container-tencent-map',
  components: {
  },
  data () {
    return {
      lng: 0,
      lat: 0,
      adr: '',
      markers: [],
      loaded: false,
      loadedTrack: false,
      marker: null, // 点标记实例
      infoWindow: null, // 信息窗实例
      polylineLayer: null, // 轨迹实例
      map: null // 地图实例
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  mounted () {
    this.initPage()
  },
  methods: {
    openInfoWindow () {
      this.infoWindow.open()
    },
    initPage () {
      this.map = new TMap.Map(document.getElementById('tencentMap'), {
        center: new TMap.LatLng(39.984120, 116.307503), // 设置中心点坐标
        zoom: 14 // 设置地图缩放级别
      })
    },
    getCarTrackMsg (carNo, startDate, endDate) {
      this.positionType = 'carTrack' // 类型设为轨迹
      const loading = this.$loading({
        lock: true,
        text: '正在获取轨迹中，请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      getGpsTrack(carNo, startDate, endDate).then(response => {
        if (response.code === 0) {
          let resObjs = JSON.parse(response.data)
          // 轨迹list
          let trackList = []
          // 点
          let markers = []
          resObjs.forEach((item, index) => {
            // 轨迹
            trackList.push(new TMap.LatLng((Number(item.lat) / 600000).toFixed(6), (Number(item.lon) / 600000).toFixed(6)))
            // 点
            markers.push({
              time: item.gtm,
              lon: (Number(item.lon) / 600000).toFixed(6),
              lat: (Number(item.lat) / 600000).toFixed(6)
            })
          })
          let startMarkers = markers.slice(0, 1) // 起点
          let endMarkers = markers.slice(-1) // 终点
          this.map.setCenter(new TMap.LatLng(endMarkers[0].lat, endMarkers[0].lon)) // 设置中心点坐标
          this.markers = markers.slice(0, 1).concat(markers.slice(-1))
          // 显示路线
          this.display_polyline(trackList)
          // 清空之前的标记
          if (this.marker) {
            this.marker.setMap(null)
            this.marker = null
          }
          // 初始化信息窗关闭
          if (this.infoWindow) {
            this.infoWindow.close()
          }
          // 标记起终点marker
          this.marker = new TMap.MultiMarker({
            id: 'marker-layer',
            map: this.map,
            styles: {
              start: new TMap.MarkerStyle({
                width: 25,
                height: 35,
                anchor: { x: 16, y: 32 },
                src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png'
              }),
              end: new TMap.MarkerStyle({
                width: 25,
                height: 35,
                anchor: { x: 16, y: 32 },
                src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png'
              })
            },
            geometries: [{
              id: 'start',
              styleId: 'start',
              // contentValue: '时间：' + startMarkers[0].time + '； 纬度：' + startMarkers[0].lat + '； 经度：' + startMarkers[0].lon,
              position: new TMap.LatLng(startMarkers[0].lat, startMarkers[0].lon)
            }, {
              id: 'end',
              styleId: 'end',
              // contentValue: '时间：' + endMarkers[0].time + '； 纬度：' + endMarkers[0].lat + '； 经度：' + endMarkers[0].lon,
              position: new TMap.LatLng(endMarkers[0].lat, endMarkers[0].lon)
            }]
          })
          this.loadedTrack = true
          this.loaded = false
          loading.close()
        } else {
          let msg = ''
          if (!validatenull(response.msg)) {
            msg = '提示：' + response.msg
          } else {
            msg = ''
          }
          this.$alert('未找到此车牌号的轨迹信息！' + msg, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
            }
          })
          this.clearMsg()
          loading.close()
        }
      }).catch(() => {
        loading.close()
      })
    },
    display_polyline (pl) {
      // 清空之前的折线
      if (this.polylineLayer) {
        this.polylineLayer.setMap(null)
        this.polylineLayer = null
      }
      // 创建 MultiPolyline显示折线
      this.polylineLayer = new TMap.MultiPolyline({
        id: 'polyline-layer', // 图层唯一标识
        map: this.map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          'style_blue': new TMap.PolylineStyle({
            'color': '#3777FF', // 线填充色
            'width': 8, // 折线宽度
            'borderWidth': 5, // 边线宽度
            'borderColor': '#FFF', // 边线颜色
            'lineCap': 'round' // 线端头方式
          })
        },
        // 折线数据定义
        geometries: [
          {
            'id': 'pl_1', // 折线唯一标识，删除时使用
            'styleId': 'style_blue', // 绑定样式名
            'paths': pl
          }
        ]
      })
    },
    getCarPositionMsg (carno) {
      this.positionType = 'carPosition' // 类型设为定位
      if (validatenull(carno)) {
        return ''
      } else {
        const loading = this.$loading({
          lock: true,
          text: '正在获取定位中，请稍等',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        getGpsPosition(carno).then(response => {
          if (response.code === 0) {
            let resObj = JSON.parse(response.data)
            this.lng = (Number(resObj.lon) / 600000).toFixed(6)
            this.lat = (Number(resObj.lat) / 600000).toFixed(6)
            this.adr = resObj.adr
            var center = new TMap.LatLng(this.lat, this.lng)
            this.map.setCenter(new TMap.LatLng(this.lat, this.lng)) // 坐标中心
            this.loaded = true
            this.loadedTrack = false
            // 清空之前的标记
            if (this.marker) {
              this.marker.setMap(null)
              this.marker = null
            }
            // 初始化信息窗关闭
            if (this.infoWindow) {
              this.infoWindow.close()
            }
            // 清空之前的折线
            if (this.polylineLayer) {
              this.polylineLayer.setMap(null)
              this.polylineLayer = null
            }

            // 添加新的标记
            this.marker = new TMap.MultiMarker({
              id: 'marker-layer', // 如果有唯一标识，可以使用id
              map: this.map,
              styles: {
                // 点标记样式
                'marker': new TMap.MarkerStyle({
                  'width': 24,
                  'height': 35,
                  'anchor': { x: 12, y: 35 },
                  'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
                })
              },
              geometries: [
                // 点标记数据数组
                {
                  // 标记位置(纬度，经度，高度)
                  'position': center,
                  'id': 'marker'
                }
              ]
            })
            // 初始化infoWindow
            this.infoWindow = new TMap.InfoWindow({
              map: this.map,
              position: new TMap.LatLng(this.lat, this.lng),
              offset: { x: 0, y: -32 }, // 设置信息窗相对position偏移像素
              content: '<div><p>' + this.adr + '</p></div>'
            })
            // 监听标注点击事件
            this.marker.on('click', this.openInfoWindow)
            loading.close()
          } else {
            let msg = ''
            if (!validatenull(response.msg)) {
              msg = '提示：' + response.msg
            } else {
              msg = ''
            }
            this.$alert('未找到此车牌号的定位信息！' + msg, '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
              }
            })
            this.clearMsg()
            loading.close()
          }
        }).catch(() => {
          loading.close()
        })
      }
    },
    clearMsg () {
      // 清空之前的标记
      if (this.marker) {
        this.marker.setMap(null)
        this.marker = null
      }
      // 初始化信息窗关闭
      if (this.infoWindow) {
        this.infoWindow.close()
      }
      // 清空之前的折线
      if (this.polylineLayer) {
        this.polylineLayer.setMap(null)
        this.polylineLayer = null
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.tencent-map-container {
  .mr-l-5 {
    margin-left: 5px;
  }
  .info_card {
    display: inline-block;
    margin: 50px auto;
    position: absolute;
    width: auto;
    height:100px;
    background-color: #c7c9c8;
    border: 5px solid #ffffff;
    color: #000000;
  }
  .info_card .title{
    width: 100%;
    height:40px;
    background-color: #000000;
    color: #ffffff;
  }

  .title span.title_name{
    position: relative;
    top: 7px;
    left: 10px;
    font-size: 18px;
  }

  .info_card .title .close_img{
    position: absolute;
    top:10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
  }
  .info_card .title .close_img .min {
    width: 0;
    height: 0;
    font-size:0;
    overflow:hidden;
    position:absolute;
    border-width:10px;
  }

  .info_card .title .close_img .top_img {
    border-style:solid dashed dashed;
    border-color:#000000 transparent transparent transparent;
    top:-2px;
  }

  .info_card .title .close_img .right_img {
    border-style:solid dashed dashed;
    border-color:transparent #000000 transparent transparent;
    left:2px;
  }

  .info_card .title .close_img .bottom_img{
    border-style:solid dashed dashed;
    border-color:transparent transparent #000000 transparent;
    top:2px;
  }

  .info_card .title .close_img .left_img{
    border-style:solid dashed dashed;
    border-color:transparent transparent transparent #000000;
    left:-2px;
  }

  .info_card span.cancle{
    width:0;
    height:0;
    font-size:0;
    overflow:hidden;
    position:absolute;
  }

  .info_card span.bot{
    border-width:20px;
    border-style:solid dashed dashed;
    border-color:#ffffff transparent transparent;
    left:80px;
    bottom:-40px;
  }
  .info_card span.top{
    border-width:20px;
    border-style:solid dashed dashed;
    border-color:#c7c9c8 transparent transparent;
    left:80px;
    bottom:-33px;
  }
  .info_card .content{
    margin-top: 10px;
    margin-left: 10px;
  }
}
  .amap-demo {
    height: 300px;
  }
  .toolbar {
    margin-top: 10px;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-tencent-map/index.vue"
}
</vue-filename-injector>
