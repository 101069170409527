import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/customerproject/page',
    method: 'post',
    data: query
  })
}

/**
 * 通过ids批量删除
 */
export function deltrids (ids) {
  return request({
    url: '/basic/customerproject/deleteCustomerProject',
    method: 'post',
    params: ids
  })
}

/**
* 通过id查询*
*/
export function tredit (obj) {
  return request({
    url: '/basic/customerproject/selectId',
    method: 'post',
    params: { id: obj }
  })
}

/**
 * 新增保存
 */
export function savetr (obj) {
  return request({
    url: '/basic/customerproject/insertSelective',
    method: 'post',
    data: obj
  })
}
/*
* 更新保存
*/
export function updatetr (obj) {
  return request({
    url: '/basic/customerproject/updateCustomerProject',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 初审
 */
export function trAudit (ids) {
  return request({
    url: '/basic/customerproject/auditDoc',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反初审
 */
export function trUnaudit (ids) {
  return request({
    url: '/basic/customerproject/auditDocUndo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 复审
 */
export function trExamine (ids) {
  return request({
    url: '/basic/customerproject/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反复审
 */
export function trUnexamine (ids) {
  return request({
    url: '/basic/customerproject/auditUndo',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单、托运单 根据 项目id、运输类型 条件查询子项目
 */
export function selectChildProject (obj) {
  return request({
    url: '/basic/customerproject/selectCustomerProject',
    method: 'post',
    params: obj
  })
}
/**
 * 货源单 根据 子项目id、始发地、目的地、车位 获取子项目短驳基地
 */
export function hyGetProjectDetails (obj) {
  return request({
    url: '/basic/customerproject/hyGetProjectDetailsByParams',
    method: 'post',
    data: obj
  })
}
/**
 * 审核 保存
 */
export function auditSubproject (obj) {
  return request({
    url: '/basic/customerproject/eachAuditNode',
    method: 'post',
    params: obj
  })
}
/**
 * 上传合同 保存到后台
 */
export function uploadAliSubproject (obj) {
  return request({
    url: '/tms/ossobject/uploadSubproject',
    method: 'post',
    data: obj
  })
}
/**
 * 获取子项目（搜索下拉）
 */
export function getChildProject (subproject) {
  return request({
    url: '/basic/customerproject/getDropDownListData',
    method: 'get',
    params: { subproject: subproject }
  })
}
/**
 * 操作结束子项目
 */
export function endSubproject (id) {
  return request({
    url: '/basic/customerproject/byStartEnableOrEnd',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 通过id进行 启用/停用
 */
export function enableObj (obj) {
  return request({
    url: '/basic/customerproject/byStartEnableOrEnd',
    method: 'post',
    params: obj
  })
}
/**
 * 通过id进行 启用/停用 明细线路
 */
export function enableDetailObj (obj) {
  return request({
    url: '/basic/customerproject/byStartEnableOrEndDetails',
    method: 'post',
    params: obj
  })
}
