<template>
    <div id="myChartBar" :style="{height:height, width: width}"></div>
</template>
<script>
// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-echarts-bar',
  data () {
    return {
      xAxisData: [],
      seriesTotalNum: [],
      seriesTotalAmount: [],
      seriesTotalProfit: []
    }
  },
  props: {
    height: {
      type: String,
      default: () => '550px'
    },
    width: {
      type: String,
      default: () => '950px'
    },
    barData: {
      type: Array,
      default: () => []
    }
  },
  create () {
    // this.initChart()
  },
  mounted () {
    // this.initChart()
  },
  methods: {
    initChart (title, seriesname1, seriesname2, seriesname3, xAxisData, seriesTotalNum, seriesTotalAmount, seriesTotalProfit) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入柱状图组件
      require('echarts/lib/chart/bar')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')

      // 清空上次数据
      this.xAxisData = []
      this.seriesTotalNum = []
      this.seriesTotalAmount = []
      this.seriesTotalProfit = []
      // 赋值
      this.xAxisData = xAxisData
      this.seriesTotalNum = seriesTotalNum
      this.seriesTotalAmount = seriesTotalAmount
      this.seriesTotalProfit = seriesTotalProfit

      var myChart = echarts.init(document.getElementById('myChartBar'))
      var colors = ['rgba(251, 114, 147, 1)', 'rgba(50, 197, 233, 1)', 'rgba(159, 230, 184, 1)']
      var options = {
        color: colors,
        title: { text: title },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: true },
            // magicType: { show: true, type: ['line', 'bar'] },
            // restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: [seriesname1, seriesname2, seriesname3],
          selected: {
            '总毛利': false,
            '总收入': false
          }
        },
        grid: {
          left: '1%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxisData,
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            formatter: function (value) {
              return value.split('').join('\n')
            }
          }
        },
        yAxis: {},
        series: [
          {
            name: seriesname1,
            type: 'bar',
            zlevel: 2,
            // barWidth: '10%',
            barGap: '100%',
            data: this.seriesTotalNum
          },
          {
            name: seriesname2,
            type: 'bar',
            zlevel: 2,
            barGap: '100%',
            data: this.seriesTotalAmount
          },
          {
            name: seriesname3,
            type: 'bar',
            zlevel: 2,
            barGap: '100%',
            data: this.seriesTotalProfit
          }]
      }
      myChart.setOption(options, true)

      myChart.on('legendselectchanged', function (obj) {
        var name = obj.name
        var selected = obj.selected
        if (name === '总票数') {
          selected.总收入 = false
          selected.总毛利 = false
        } else if (name === '总收入') {
          selected.总票数 = false
          selected.总毛利 = false
        } else if (name === '总毛利') {
          selected.总票数 = false
          selected.总收入 = false
        }
        options.legend.selected = selected
        myChart.setOption(options, true)
      })
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-bar/index.vue"
}
</vue-filename-injector>
