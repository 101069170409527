<template>
  <el-row>
    <el-col :span="24">
        <el-upload
            class="upload-ali-cancel"
            ref="refUploadAli"
            action
            :data="uploadData"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :auto-upload="false"
            accept=".jpg,.jpeg,.png,.pdf,.gif"
            :before-upload="beforeAvatarUpload"
            multiple
            :on-exceed="handleExceed"
            :on-change="handleChange"
            :on-success="onSuccess"
            :on-error="onError"
            list-type="picture"
            :file-list="fileList">
          <el-button slot="trigger" size="mini" type="primary" icon="el-icon-plus">选择图片</el-button>
        </el-upload>
    </el-col>
  </el-row>
</template>
<script>

import store from '@/store/index'
import { uploadAli, getOssCusList, delOssData, aliBatchDownload, getOssObjectList
} from '@/api/basic/customer'
import { aliBatchSalesDownload } from '@/api/tms/accfp'
import axios from 'axios'
import dayjs from 'dayjs'
import { validatenull } from '@/tdcommon/validate'
import { takePicture, dtaURItoBlob } from '@/tdcommon/beeutil'
import { uploadAliCancel } from '@/api/tms/tmszz'

export default {
  name: 'd2-container-uploadAli-cancel',
  data () {
    return {
      loading: false,
      fileList: [],
      fileListMsg: [],
      activeNames: ['1'],
      formData: [],
      resultData: [],
      totalNumbers: 0,
      importNumbers: 0,
      filelistdata: {},
      uploadData: { module: this.customerType },
      headers: {
        'Authorization': 'Bearer ' + store.getters.access_token,
        'TENANT-ID': 1
      },
      uploadForm: [],
      activeName: 'picture',
      // srcList: [
      //   'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
      //   'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      // ],
      srcList: [],
      fileSrcList: [],
      videoSrcList: [],
      delOssId: [],
      updateNo: '',
      objectList: '',
      updateType: '',
      uploadStatus: false,
      editStatus: false, // 当前状态
      openLoading: false,
      auditStatus: '', // 上传审核状态
      auditText: '', // 已审核的文字提示
      uploadClassify: '' // 上传分类
    }
  },
  props: {
    // percentage: {
    //   type: Number,
    //   default: () => 0
    // },
    importVisible: {
      type: Boolean,
      default: () => false
    },
    uploadVisible: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    // url: {
    //   type: String,
    //   default: () => '/basic/dtcustomer/import/yyd'
    // },
    // customerNo: {
    //   type: String,
    //   default: () => ''
    // },
    customerType: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    customerType: function (val, oldVal) {
      this.$set(this.uploadData, 'module', val)
    }
  },
  methods: {
    // 上传
    submitUpload (importType) {
      console.log('上传submitUpload')
      this.loading = true
      if (!validatenull(this.fileListMsg)) { // 判断文件是否为空
        if (this.auditStatus === '1') { // 判断上传是否已审核
          this.$message.error(this.auditText + '，不能更改！上传文件请先反审核！')
          this.loading = false
        } else {
          let promiseArr = this.fileListMsg.map(item => this.picUpload(item))
          // 等待全部上传完成
          Promise.allSettled(promiseArr).then(res => {
            console.log('promiseArr')
            setTimeout(() => {
              this.$message.success('上传成功！')
              this.getOssReturnData() // 调用返回数据接口
              // if (this.uploadStatus) {
              //   this.$message.success('上传成功！')
              // } else {
              //   this.$message.error('上传失败！')
              // }
            }, 1500)
          }).catch(err => {
            this.$message.error(err)
          })
          // this.fileListMsg.forEach(item => {
          //   this.picUpload(item)
          // })
          // setTimeout(() => {
          //   this.getOssReturnData() // 调用返回数据接口
          //   if (this.uploadStatus) {
          //     this.$message.success('上传成功')
          //   } else {
          //     this.$message.error('上传失败')
          //   }
          // }, 1500)
        }
      } else {
        this.$message.error('请选择上传文件！')
        this.loading = false
      }
    },
    async picUpload (file) {
      uploadAli().then(response => {
        console.log('uploadAli', response)
        var data = response.data
        var ossData = new FormData()
        // var imgType = file.raw.name.split('.')[1]
        let fileNamePoint = file.raw.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
        let fileNameLength = file.raw.name.length // 取到文件名长度
        let imgType = file.raw.name.substring(fileNamePoint + 1, fileNameLength).toLocaleLowerCase() // 截  所有后缀格式转小写
        // console.log('fileName', fileNamePoint + 1)
        // console.log('fileNameLength', fileNameLength)
        // console.log('imgType', imgType)
        // var fileName = this.updateNo + '_' + dayjs(new Date()).format('YYYYMMDDHHmmssSSS') + Math.random().toString().substr(2, 4) + '.' + imgType // 上传阿里保存 文件名=单位编码+日期（年月日时分秒+毫秒3位数）+4位随机码+文件类型
        var fileName = this.updateNo + Math.random().toString().substr(2, 3) + file.raw.name // 上传阿里保存 文件名=单位编码+3位随机码+原有文件名
        ossData.append('name', fileName)
        // key就代表文件层级和阿里云上的文件名
        // let filename = file.file.name + file.file.size
        // let keyValue = data.dir + '/' + filename + '.' + imgType
        let keyValue = data.dir + fileName
        ossData.append('key', keyValue)
        ossData.append('policy', data.policy)
        ossData.append('OSSAccessKeyId', data.accessid)
        ossData.append('success_action_status', 200)
        ossData.append('signature', data.signature)
        ossData.append('callback', data.callback)
        ossData.append('file', file.raw, file.raw.name)
        axios.post(data.host, ossData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          let fileType = ''
          if (imgType === 'jpg' || imgType === 'jpeg' || imgType === 'png' || imgType === 'gif') { // .jpg,.jpeg,.png,.gif
            fileType = 'picture'
          } else if (imgType === 'xls' || imgType === 'xlsx' || imgType === 'doc' || imgType === 'docx' || imgType === 'pdf') { // xls,.xlsx,.doc,.docx,.pdf
            fileType = 'file'
          } else if (imgType === 'avi' || imgType === 'wmv' || imgType === 'mpeg' || imgType === 'mov' || imgType === 'rm' || imgType === 'rmvb' || imgType === 'flv' || imgType === 'mkv' || imgType === 'mp4') { // .avi,.wmv,.mpeg,.mov,.rm,.rmvb,.flv,.mkv,.mp4
            fileType = 'video'
          } else if (imgType === 'mp3' || imgType === 'wma' || imgType === 'flac' || imgType === 'ape' || imgType === 'wav' || imgType === 'aac' || imgType === 'm4a') { // .mp3,.wma,.flac,.ape,.wav,.aac,.m4a
            fileType = 'music'
          }
          if (res.status === 200) {
            // let url = data.host // 上传阿里保存 传值URL
            uploadAliCancel(Object.assign({
              url: 'https://file.56tms.com/' + keyValue,
              fileName: fileName,
              objectNo: this.updateNo, // 单位编号
              objectList: this.objectList, // 单号list
              source: 'tms', // 来源
              module: this.customerType, // 模块设置为单据类型
              itemType: this.uploadClassify, // 类型
              fileType: fileType, // 文件类型
              fileDesc: this.uploadData.fileDesc // 文件说明
            })).then(items => {
              if (items) {
                this.srcList = []
                this.fileSrcList = []
                this.videoSrcList = []
                console.log('this.$refs.refUploadAli', this.$refs.refUploadAli)
                // this.$refs.refUploadAli.clearFiles() // 清空上传文件列表
                this.fileListMsg = [] // 清空handlechange的文件缓存
                this.loading = false
                this.uploadStatus = true
              }
            })
          }
        }).catch(() => {
        })
      })
    },
    handleChange (file, fileList) {
      this.fileListMsg = fileList
      console.log('this.fileListMsg', this.fileListMsg)
      this.$emit('setFileListMsg', this.fileListMsg)
    },
    handleRemove (file, fileList) {
    },
    handlePreview (file, fileList) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // 高拍仪拍照上传
    BeatMeterUpload () {
      if (takePicture('videoMark', 'canvasMark', 'take', this)) {
        this.loading = true
        const tempSrc = document.getElementById('canvasMark')
        const base64Data = tempSrc.toDataURL('image/jpeg', 1.0)
        let blob = dtaURItoBlob(base64Data)
        blob.lastModifiedDate = new Date()
        blob.name = this.updateNo + '_' + dayjs(new Date()).format('YYYYMMDDHHmmssSSS') + Math.random().toString().substr(2, 4) + '.jpeg'
        var fd = { name: blob.name, percentage: 0, raw: blob, size: blob.size, status: 'ready' }
        this.picUpload(fd)
        setTimeout(() => {
          this.loading = false
          this.getOssReturnData() // 调用返回数据接口
          if (this.uploadStatus) {
            this.$message.success('上传成功')
          } else {
            this.$message.error('上传失败')
          }
        }, 1500)
      }
    },
    // 高拍仪设备操作
    openBeatMeter () {
      this.openLoading = true
      if (!validatenull(this.fileListMsg)) {
        this.$message.warning('请先将选择文件进行上传或删除后，再进行开启拍摄功能')
        this.openLoading = false
        return
      }
      if (!this.editStatus) {
        this.editStatus = true
        setTimeout(() => {
          takePicture('videoMark', 'canvasMark', 'open', this)
          setTimeout(() => {
            this.openLoading = false
          }, 1500)
        }, 500)
      } else {
        takePicture('videoMark', 'canvasMark', 'close', this)
        setTimeout(() => {
          this.editStatus = false
          this.openLoading = false
        }, 500)
      }
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
    },
    // 判断导入的格式是不是xls、xlsx、...，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      const isIMAGE = file.type === '.jpg' || '.jpeg' || '.png' || '.gif' // .jpg,.jpeg,.png,.gif
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 50MB!')
        return false
      }
      return isIMAGE && isLt2M
    },
    // 上传成功
    onSuccess (res, file, fileList) {
      // let val = ''
      // fileList.forEach(item => {
      //   if (val !== '') {
      //     val += ','
      //   }
      //   if (item.response) {
      //     val += item.response.data.downloadPath
      //   } else {
      //     val += item.url
      //   }
      // })
      // this.$emit('input', val)
      // this.uploadAliyun(res, file)
    },
    // 上传失败
    onError () {
    },
    // 获取客户编号
    getupdateNo (cusNo, type, auditStatus, classify, auditText, objectList) {
      this.updateNo = cusNo
      this.objectList = objectList
      this.updateType = type
      this.auditStatus = auditStatus // 上传审核状态（'1'为已审核，'0'为未审核）
      this.uploadClassify = classify // 上传分类
      this.auditText = auditText // 已审核的文字提示
      this.$set(this.uploadData, 'objectNo', cusNo)
    },
    // 关闭时清空srclist
    clearSrcList () {
      this.srcList = []
      this.fileSrcList = []
      this.videoSrcList = []
      if (this.editStatus) {
        takePicture('videoMark', 'canvasMark', 'close', this)
        this.editStatus = false
        this.openLoading = false
      }
      this.$set(this.uploadData, 'objectNo', '') // 清空编号
      this.$set(this.uploadData, 'fileDesc', '') // 清空文件说明
      // this.$refs.refUploadAli.clearFiles() // 清空上传文件列表
    },
    listString (imageList) {
      var imageArray = []
      imageList.forEach(item => {
        imageArray.push(item.url)
      })
      return imageArray
    },
    // 获取上传返回的数据
    getOssReturnData () {
      this.loading = true
      if (this.updateType === 'transfer' || this.updateType === 'shortbill' || this.updateType === 'delivery' || this.updateType === 'advorder' || this.updateType === 'supply' || this.updateType === 'waybill' || this.updateType === 'transaction') { // 获取 中转单、短驳单、送货单、预订单、货源单、托运单、异动单作废上传图片返回值
        getOssObjectList(Object.assign({
          objectNo: this.updateNo,
          itemType: this.uploadClassify
        })).then(item => {
          this.loading = false
          if (item.code === 0) {
            item.data.forEach(urls => {
              let option = {}
              option.id = urls.id
              option.url = urls.url
              option.fileName = urls.fileName
              option.itemType = urls.itemType
              option.fileType = urls.fileType
              if (option.fileType === 'picture') {
                this.srcList.push(option)
                this.delOssId.push(option)
              } else if (option.fileType === 'file') {
                this.fileSrcList.push(option)
              } else if (option.fileType === 'video' || option.fileType === 'music') {
                this.videoSrcList.push(option)
              }
            })
          }
        }).catch(() => {
          this.loading = false
        })
      } else {
        getOssCusList(this.updateNo).then(item => {
          this.loading = false
          if (item.code === 0) {
            item.data.forEach(urls => {
              let option = {}
              option.id = urls.id
              option.url = urls.url
              option.fileName = urls.fileName
              option.itemType = urls.itemType
              option.fileType = urls.fileType
              if (option.fileType === 'picture') {
                this.srcList.push(option)
                this.delOssId.push(option)
              } else if (option.fileType === 'file') {
                this.fileSrcList.push(option)
              } else if (option.fileType === 'video' || option.fileType === 'music') {
                this.videoSrcList.push(option)
              }
            })
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    // 预览返回图片
    handlePictureCardPreview (file) {
      // this.$refs.image_refs.clickHandler()
    },
    // 删除返回图片
    handlePictureCardRemove (option, index, val) {
      if (this.auditStatus === '1') {
        this.$message.error(this.auditText + '，不能更改！删除图片请先反审核！')
      } else {
        delOssData(option.id).then(item => {
          if (item.code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.srcList.splice(index, 1) // 前端页面移除
          } else {
            this.$message.error(item.msg)
          }
        })
      }
    },
    batch_download () {
      const loading = this.$loading({
        lock: true,
        text: '正在下载中，请稍等。。。',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if (this.updateType === 'transfer' || this.updateType === 'receipt' || this.updateType === 'driverContract' || this.updateType === 'transferSign' || this.updateType === 'receivableStatement' || this.updateType === 'receivableSignature' || this.updateType === 'defrayment' || this.updateType === 'paymentCertificate' || this.updateType === 'paymentReceipt' || this.updateType === 'productPicture' || this.updateType === 'coverPicture' || this.updateType === 'biddingDocuments' || this.updateType === 'salesRevenue' || this.updateType === 'subproject' || this.updateType === 'repairmanagement' || this.updateType === 'tyremanagement' || this.updateType === 'fleetCosts' || this.updateType === 'inquirysheet' || this.updateType === 'shortbill' || this.updateType === 'shortSign' || this.updateType === 'delivery' || this.updateType === 'deliverySign') { // 应收账单 对账单、签收单
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: this.uploadClassify
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      } else if (this.updateType === 'salesApplication') { // 销项发票申请单
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: 'FPSQ'
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      } else if (this.updateType === 'salesStatement') { // 销项发票对账单
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: 'FPDZ'
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      } else if (this.updateType === 'salesSignature') { // 销项发票签收单
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: 'FPQS'
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      } else if (this.updateType === 'cusAberrant') { // 项目管理 合同
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: 'HTSC'
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      } else if (this.updateType === 'qualifications') { // 项目管理 客户资质
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: 'KHZZSC'
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      } else if (this.updateType === 'confirmationLetter') { // 项目管理 确认函
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: 'QRHSC'
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      } else if (this.updateType === 'currentInformation') { // 项目管理 往来资料
        aliBatchSalesDownload(Object.assign({
          objectNo: this.uploadData.objectNo,
          itemType: 'WLZLSC'
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      } else {
        aliBatchDownload(Object.assign({
          objectNo: this.uploadData.objectNo
        })).then(response => {
          let blob = new Blob([response], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = this.customerType + this.updateNo // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          setTimeout(() => {
            loading.close()
          }, 2000)
        })
      }
    },
    // 下载返回文件
    handleFileDownload (option, index, val) {
      let fileNamePoint = option.fileName.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
      let fileNameLength = option.fileName.length // 取到文件名长度
      let imgType = option.fileName.substring(fileNamePoint + 1, fileNameLength) // 截

      if (imgType === 'pdf') { // 如果文件类型是pdf
        window.open(option.url)
      } else { // 如果是其他文件类型
        const ele = document.createElement('a')
        ele.setAttribute('href', option.url) // 设置下载文件的url地址
        ele.setAttribute('download', option.fileName) // 用于设置下载文件的文件名
        ele.click()
      }
    },
    // 下载返回音频、视频
    handleVideoDownload (option, index, val) {
      // const ele = document.createElement('a')
      // ele.setAttribute('href', option.url) // 设置下载文件的url地址
      // ele.setAttribute('download', option.fileName) // 用于设置下载文件的文件名
      // ele.click()
      // 将lob对象转换为域名结合式的url
      // const link = document.createElement('a')
      // link.style.display = 'none'
      // // 设置a标签的下载属性，设置文件名及格式，后缀名最好让后端在数据格式中返回
      // link.download = option.fileName
      // var blob = new Blob(option.url)
      // link.href = URL.createObjectURL(blob)
      // // 自触发click事件
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)
      window.open(option.url)
    },
    // 删除返回文件
    handleFileDelete (option, index, val) {
      if (this.auditStatus === '1') {
        this.$message.error(this.auditText + '，不能更改！删除文件请先反审核！')
      } else {
        delOssData(option.id).then(item => {
          if (item.code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.fileSrcList.splice(index, 1) // 前端页面移除
          } else {
            this.$message.error(item.msg)
          }
        })
      }
    },
    // 删除返回音频、视频
    handleVideoDelete (option, index, val) {
      if (this.auditStatus === '1') {
        this.$message.error(this.auditText + '，不能更改！删除音频、视频请先反审核！')
      } else {
        delOssData(option.id).then(item => {
          if (item.code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.videoSrcList.splice(index, 1) // 前端页面移除
          } else {
            this.$message.error(item.msg)
          }
        })
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
.upload-ali-cancel {
  .el-upload-list {
    max-height: 250px;
    overflow-y: auto;
  }
}
.uploadali-el-card-style {
    .el-card__header {
        padding: 14px 20px !important;
        border-bottom: 0 !important;
    }
}
.uploadImage {
    position: relative;
    display: inline-block;
    margin-left: 17px;
    .optionMark {
        display: none;
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50px;
        top: 50px;
        background:linear-gradient(to top left,rgba(0,0,0,.5) 40%,transparent 40%);

        .el-upload-list__item-actions {
            position: absolute;
            font-size: 15px;
            left: 6px;
            top: 26px;
            width: 100%;
            height: 100%;
            text-align: right;
            color: #fff;
            transition: opacity .3s;
        }
    }
}
.uploadImage:hover {
    .optionMark {
        display: block;
        z-index: 999;
        .el-upload-list__item-preview {
            cursor: pointer;
        }
    }
}
.file_download {
  display: flex;
  justify-content: center;
  align-items: center;
  //border-bottom: 1px solid #EBEEF5;
}
/*文件下载删除 鼠标移入样式改变*/
.file_download:hover{
    cursor: pointer;
    .file_download_delete{display: block};
}
/*文件下载删除按钮*/
.file_download_delete{
  display: none;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-uploadAli-cancel/index.vue"
}
</vue-filename-injector>
