<template>
    <el-dialog title="打印记录" :visible.sync="printRecordsVisible" :width="dialogWidth" style="height: 90%;" :close-on-click-modal="false" append-to-body :before-close="handleClose">
      <div style="margin-bottom: 10px;">
        <el-button type="primary" title="刷新打印记录" size="mini" icon="el-icon-refresh" @click.native.prevent="handleRefresh()">刷新记录</el-button>
      </div>
      <el-table
          :data="tableData"
          v-loading="loading"
          :element-loading-text="loading_text"
          :height="table_height"
          border
          style="width: 100%">
        <el-table-column prop="submissionDate" label="日期" width="160" align="center"></el-table-column>
        <el-table-column prop="statusType" label="报价单 / 确认函" width="120" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.statusType === '确认函'" style="font-weight: bold;color: #409eff;">确认函</span>
            <span v-else style="font-weight: bold;color: #909399;">报价单</span>
          </template>
        </el-table-column>
        <el-table-column prop="operatorName" label="操作人" align="center" width="100"></el-table-column>
        <el-table-column prop="customerLineName" label="线路名称" align="center"></el-table-column>
        <el-table-column prop="operate" label="操作" width="220" align="center">
          <template slot-scope="scope">
            <div style="display: flex;flex-direction: row;justify-content: center;">
              <el-button v-if="scope.row.statusType === '报价单'" type="success" title="查看" size="mini" @click.native.prevent="handleSee(scope.row)">查看</el-button>
              <div v-else style="width: 56px;margin-right: 10px;"></div>
              <el-button type="danger" title="删除" size="mini" @click.native.prevent="handledel(scope.row)">删除</el-button>
              <el-button type="primary" title="打印" size="mini" @click.native.prevent="handlePrint(scope.row)">打印</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--分页显示-->
      <el-pagination
          v-if="isPagination"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="50"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.page.total"
          :style="isPagination !== 'right'? 'text-align:left' : 'text-align:right'">
      </el-pagination>
    </el-dialog>
</template>
<script>
import { deletePrintRecords, fetchPrintList, getPrintRecord } from '@/api/basic/subprojectCost'

export default {
  name: 'd2-container-print-records',
  data () {
    return {
      tableData: [],
      loading: false,
      page: {
        total: 0, // 总条数
        currentPage: 1, // 当前页
        pageSize: 50, // 每页显示多少条
        descs: ['create_time']
      }
    }
  },
  props: {
    printRecordsVisible: {
      type: Boolean,
      default: () => false
    },
    dialogWidth: {
      type: String,
      default: () => '50%'
    },
    // 加载文字信息
    loading_text: {
      type: String,
      default: () => '正在加载数据，请稍等.....'
    },
    // 表格高度
    table_height: {
      type: [String, Number],
      default: () => '500px'
    },
    // 分页显示位置
    isPagination: {
      type: [Boolean, String],
      default: () => 'right'
    },
    subprojectsId: {
      type: Number,
      default: () => null
    }
  },
  methods: {
    handleRefresh () {
      this.remoteMethod()
    },
    remoteMethod () {
      console.log('subprojectsId', this.subprojectsId)
      this.loading = true
      fetchPrintList(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize,
        descs: this.page.descs,
        oneList: { subprojectsId: this.subprojectsId }
      })).then(response => {
        this.loading = false
        console.log('获取打印记录', response)
        this.tableData = response.data.records
        this.page.total = response.data.total
        this.page.pageSize = response.data.size
        this.page.currentPage = response.data.current
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    // 关闭时动作处理
    handleClose (done) {
      this.$emit('exportHandleClose', false)
    },
    handleSee (row) {
      getPrintRecord(row.id).then(res => {
        console.log('getPrintRecord', res)
        this.$emit('openPrintRecordsMsg', res.data, row.projectDataType, true)
      })
    },
    handledel (row) {
      let titles = ''
      titles = '你确定要删除此条' + row.statusType + '打印记录吗？' // 多条
      this.$confirm(titles, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePrintRecords(row.id).then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功！')
            this.remoteMethod()
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    handlePrint (row) {
      this.$emit('handlePrintRecordsMsg', row)
    },
    // 每页条数
    handleSizeChange (val) {
      this.page.pageSize = val
      this.remoteMethod()
    },
    // 当前页
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.remoteMethod()
    }
  },
  watch: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
  .td_option {
    height: 50px;
    display: flex;
    align-items: center;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-print-records/index.vue"
}
</vue-filename-injector>
