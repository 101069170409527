<template>
  <el-cascader
    :style="styles"
    v-model="state"
    ref="cascaderAddr"
    :props="{ value: 'code', label: 'name' }"
    :options="restaurants"
    @change="handleChange"
    :clearable="clearable"
    :disabled="disabled"
    :size="size"
    filterable>
  </el-cascader>
</template>
<script>
// import { InputDistrict } from '@/api/sys.golde'   :props="{value: 'adcode', label: 'name', children: 'districts'}"
import { validatenull } from '@/tdcommon/validate'
// import { regionData } from 'element-china-area-data'
import regionData from '@/tdcommon/region.json'

export default {
  name: 'd2-container-cascader',
  data () {
    return {
      LocationName: '中国',
      // 查询结果
      restaurants: regionData,
      state: this.stringToArray()
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      default: () => ''
    },
    styles: {
      type: String,
      default: () => 'width: 100%'
    }
  },
  methods: {
    // 数据进行String to ArrayList
    stringToArray () {
      console.log('this.value:', this.value)
      return this.value.split(',')
    },
    // 获取始发地、目的地编码和中文地址
    handleChange () {
      if (this.state.length > 0) {
        let thsName = this.getChineseName(this.state).join('-')
        // console.log('this.getChineseName(this.state):', this.getChineseName(this.state))
        // console.log('thsName:', thsName)
        // console.log('getNoSXQ:', this.getNoSXQ(this.state))
        let val = { value: this.state.toString(), label: thsName }
        console.log('始发地、目的地编码和中文地址', val)
        this.$emit('change', val)
      }
    },
    loadAll () {
      // console.log('restaurants:', this.restaurants)
      // console.log('getNoSXQ:', this.getNoSXQ(this.state))
      this.getNoSXQ(this.state)
      // InputDistrict(this.LocationName, 3).then(response => {
      //   if (response.info === 'OK') {
      //     this.restaurants = this.getTreeData(response.districts[0].districts)
      //   } else {
      //     this.restaurants = []
      //   }
      // }).catch(() => {
      //   this.restaurants = []
      // })
    },
    getNoSXQ (val) {
      console.log('getNoSXQ', val)
      var addrName = []
      this.restaurants.forEach(item => {
        // console.log('一级：', item)
        if (item.code === val[0]) {
          addrName.push(item.code)
          item.children.forEach(disItem => {
            // console.log('二级：', disItem)
            if (disItem.code === val[1]) {
              addrName.push(disItem.code)
              disItem.children.forEach(items => {
                // console.log('三级：', items)
                if (items.code === val[2]) {
                  if (items.name === '市辖区') {
                    addrName.push('')
                  } else {
                    addrName.push(items.code)
                  }
                  return addrName
                }
              })
            }
          })
        }
      })
      return addrName
    },
    // 最后一级children为空时，递归方式设为不显示
    getTreeData (data) {
      for (var i = 0; i < data.length; i++) {
        // console.log('data[i]:', data[i])
        if (data[i].districts.length < 1) {
          data[i].districts = undefined
        } else {
          this.getTreeData(data[i].districts)
        }
      }
      return data
    },
    // 通过value查询中文名称
    getChineseName (val) {
      var addrName = []
      this.restaurants.forEach(item => {
        // console.log('一级：', item)
        if (item.code === val[0]) {
          addrName.push(item.name)
          item.children.forEach(disItem => {
            // console.log('二级：', disItem)
            if (disItem.code === val[1]) {
              addrName.push(disItem.name)
              if (val.length > 2) {
                disItem.children.forEach(items => {
                  // console.log('三级：', items)
                  if (items.code === val[2]) {
                    addrName.push(items.name)
                    return addrName
                  }
                })
              } else {
                return addrName
              }
            }
          })
        }
      })
      return addrName
    }
  },
  watch: {
    state: function (val, oldVal) {
      // console.log('zhuantai:', this.getNoSXQ(val))
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      // console.log('value:', val)
      // console.log('this.state:', this.state)
      if (!validatenull(val)) {
        this.state = val.split(',')
      } else {
        this.state = []
      }
    }
  },
  mounted () {
    this.loadAll()
  }
}
</script>

<style lang="scss" scoped>
  .td-autocomplete {
    li {
      line-height: normal;
      padding: 7px;
      .name {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }
      .highlighted .addr {
        color: #ddd;
      }
    }
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-cascader/index.vue"
}
</vue-filename-injector>
