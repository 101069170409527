import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/bms/finacepayment/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/bms/finacepayment',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/bms/finacepayment/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/bms/finacepayment/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/bms/finacepayment',
    method: 'put',
    data: obj
  })
}
/**
 * 保存
 */
export function qkadd (obj) {
  return request({
    url: '/bms/finacepayment/qkadd',
    method: 'post',
    data: obj
  })
}

export function qkedit (obj) {
  return request({
    url: '/bms/finacepayment/qkupdate',
    method: 'post',
    data: obj
  })
}

export function delids (ids) {
  return request({
    url: '/bms/finacepayment/delids',
    method: 'post',
    params: ids
  })
}
/**
 * 生成收付款之前判断返回值
 */
export function sfkgenerate (obj) {
  return request({
    url: '/bms/finacepayment/generate',
    method: 'post',
    data: obj
  })
}
/**
 * 生成收付款获取数据
 */
export function sfkgetobjs (ids) {
  return request({
    url: '/bms/finacepayment/generatepz',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行查询请款单预警信息
 */
export function selectEarlyWarning (ids) {
  return request({
    url: '/bms/earlyWarning/selectEarlyWarning',
    method: 'post',
    params: ids
  })
}
/**
   * 通过ids进行审单
   */
export function sfkAudit (warningRemark, ids) {
  return request({
    url: '/bms/finacepayment/sfkAudit',
    method: 'post',
    data: Object.assign({ type: '0', state: '1', isAudit: '1', warningRemark: warningRemark }, ids)
  })
}
/**
 * 通过ids进行反审单
 */
export function sfkUnaudit (ids) {
  return request({
    url: '/bms/finacepayment/sfkUnaudit',
    method: 'post',
    data: Object.assign({ type: '0', state: '0', isAudit: '0' }, ids)
  })
}
/**
 * 通过ids进行审核
 */
export function sfkExamine (ids) {
  return request({
    url: '/bms/finacepayment/sfkExamine',
    method: 'post',
    data: Object.assign({ type: '1', state: '1', isAudit: '1' }, ids)
  })
}
/**
 * 通过ids进行反审核
 */
export function sfkUnexamine (ids) {
  return request({
    url: '/bms/finacepayment/sfkUnexamine',
    method: 'post',
    data: Object.assign({ type: '1', state: '0', isAudit: '0' }, ids)
  })
}
/**
 * 通过ids进行财务
 */
export function sfkFinance (ids) {
  return request({
    url: '/bms/finacepayment/sfkFinance',
    method: 'post',
    data: Object.assign({ type: '2', state: '1', isAudit: '1' }, ids)
  })
}
/**
 * 通过ids进行财务反审核
 */
export function sfkUnFinance (ids) {
  return request({
    url: '/bms/finacepayment/sfkUnFinance',
    method: 'post',
    data: Object.assign({ type: '2', state: '0', isAudit: '0' }, ids)
  })
}
/**
 * 通过ids进行经理审核
 */
export function sfkManager (ids) {
  return request({
    url: '/bms/finacepayment/sfkManager',
    method: 'post',
    data: Object.assign({ type: '3', state: '1', isAudit: '1' }, ids)
  })
}
/**
 * 通过ids进行经理反审核
 */
export function sfkUnManager (ids) {
  return request({
    url: '/bms/finacepayment/sfkUnManager',
    method: 'post',
    data: Object.assign({ type: '3', state: '0', isAudit: '0' }, ids)
  })
}
/**
 * 上传阿里 请款管理保存到后台
 */
export function addUploadAliFinace (obj) {
  return request({
    url: '/tms/ossobject/finace',
    method: 'post',
    data: obj
  })
}
/**
 * 打印
 */
export function printFinacepayment (obj) {
  return request({
    url: '/bms/finacepayment/reportPrinting',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 手动冻结请款单
 */
export function freeze (obj) {
  return request({
    url: '/bms/finacepayment/automaticFreezing',
    method: 'post',
    params: obj
  })
}
/**
 * 手动冻结请款单
 */
export function unfreeze (ids) {
  return request({
    url: '/bms/finacepayment/thawFreezing',
    method: 'post',
    params: ids
  })
}
/**
 * 请款单跳伙伴流水
 */
export function generatehb (ids) {
  return request({
    url: '/bms/finacepayment/generatehb',
    method: 'post',
    params: ids
  })
}
/**
 * 未通过原因 保存
 */
export function notPassSave (ids, obj) {
  return request({
    url: '/bms/finacepayment/failedExamine',
    method: 'post',
    params: Object.assign({ reason: obj }, ids)
  })
}
export function pushYunShun (id) {
  return request({
    url: '/bms/finacepayment/pushYunShunData/' + id,
    method: 'get'
  })
}
export function queryPayObj (obj) {
  return request({
    url: '/basic/dtlogisticbankinfo/queryLogisticId',
    method: 'get',
    params: { logisticId: obj }
  })
}
/**
 * 二级表导出*
 */
export function secondFloorExport (obj) {
  return request({
    url: '/ex/export/exportPleasePayDetails',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 请款单生成生成收款二维码
 */
export function getWxCode (id) {
  return request({
    url: '/bms/finacepayment/generateCollectionCode',
    method: 'post',
    params: Object.assign({ id: id })
  })
}
/**
 * 三级表导出*
 */
export function thirdFloorExport (obj) {
  return request({
    url: '/ex/export/exportPleasePayThreeTable',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 批量生成收付款*
 */
export function generateBatch (obj) {
  return request({
    url: '/bms/finacepayment/generateBatch',
    method: 'post',
    params: obj
  })
}
/**
 * 批量生成伙伴收付款*
 */
export function generatehbBatch (obj) {
  return request({
    url: '/bms/finacepayment/generatehbBatch',
    method: 'post',
    params: obj
  })
}
