<template>
  <el-dialog class="describe_dialog_style" title="" v-if="describeVisible" :visible.sync="describeVisible" :before-close="close" :close-on-click-modal="false" :show-close="false" destroy-on-close append-to-body width="50%">
    <div slot="title" style="display: flex;flex-direction: row;justify-content: space-between;">
      <div style="color: #06c0c0;font-weight: bold;font-size: 18px;">
        <i class="el-icon-question"></i>
        <span style="margin-left: 4px;">费用项说明：</span>
      </div>
      <el-button size="mini" @click="close">关闭</el-button>
    </div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="短驳单" name="DT">
        <el-descriptions :column="2" border>
          <el-descriptions-item label-class-name="my-label" content-class-name="my-content" v-for="(item, index) in dtList" :key="index" :label="item.label">{{item.content}}</el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="中转单" name="ZZ">
        <el-descriptions :column="2" border>
          <el-descriptions-item label-class-name="my-label" content-class-name="my-content" v-for="(item, index) in zzList" :key="index" :label="item.label">{{item.content}}</el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="送货单" name="SH">
        <el-descriptions :column="2" border>
          <el-descriptions-item label-class-name="my-label" content-class-name="my-content" v-for="(item, index) in shList" :key="index" :label="item.label">{{item.content}}</el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>

// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-describe-dialog',
  data () {
    return {
      describeVisible: false,
      activeName: 'DT',
      dtList: [
        { label: '自有车-运输成本-油卡-13%', content: '自购车辆中石油/中石化油卡加油' },
        { label: '运输成本-油卡-13%', content: '自有车辆使用油卡及用于外包业务支付油卡' },
        { label: '运输成本-油卡-9%', content: '网货平台支持支付油卡，与运输费用一并开票' },
        { label: '运输成本-燃汽卡-9%', content: '网货平台支持支付燃汽卡，与运输费用一并开票' },
        { label: '运输成本-尿素-0%', content: '自购车辆购买尿素' },
        { label: '运输成本-尿素-13%', content: '自购车辆购买尿素' },
        { label: '运输成本-公路运费-9%', content: '供应商短途倒短运输成本' },
        { label: '运输成本-海运运费（内贸）-9%', content: '内贸海陆运输' },
        { label: '运输成本-压车费-9%', content: '司机到拉货地或送货地，因排队等待装卸货产生压车费' },
        { label: '运输成本-压车费-6%', content: '司机到拉货地或送货地，因排队等待装卸货产生压车费' },
        { label: '运输成本-放空费-6%', content: '下单后因货物未达到装货状态，司机空跑产生放空费' },
        { label: '运输成本-补差价-运输-9%', content: '付给司机一些临时打杂费，现场结清' },
        { label: '自有车-运输成本-ETC过路费-9%', content: '自有车辆ETC过路过桥费' },
        { label: '自有车-运输成本-ETC过路费-3%', content: '自有车辆ETC过路过桥费' },
        { label: '自有车-运输成本-ETC过路费-1%', content: '自有车辆ETC过路过桥费' },
        { label: '自有车-运输成本-ETC过路费-0%', content: '自有车辆ETC过路过桥费' },
        { label: '自有车-运输成本-停车费-6%', content: '自有车辆停车费' },
        { label: '自有车-运输成本-拖车费-6%', content: '自有车辆拖车费' },
        { label: '自有车-运输成本-车辆保险费-6%', content: '自有车辆年度车辆保险' },
        { label: '自有车-运输成本-车辆维修费-13%', content: '自有车辆车辆维修费' },
        { label: '自有车-运输成本-车辆维修费-6%', content: '自有车辆车辆维修费' },
        { label: '运输成本-短驳租车-9%', content: '租赁车辆用于倒短运输' },
        { label: '装卸成本-装卸费-6%', content: '货物装卸搬运成本' },
        { label: '装卸成本-临时用工成本费-6%', content: '现场临时需要帮忙搬运货物，请临时工费用' },
        { label: '装卸成本-临时叉车工-6%', content: '现场请临时叉车工费用' },
        { label: '装卸成本-吊机费-6%', content: '重货吊机费' },
        { label: '包装材料-耗材费-13%', content: '货物包装材料、防护用品，批量购买对公付款' },
        { label: '包装材料-耗材费-6%', content: '临时性紧急购买包装材料' },
        { label: '租赁-叉车租赁-13%', content: '对公付款叉车租赁费' },
        { label: '租赁-叉车租赁-3%', content: '对公付款叉车租赁费' },
        { label: '租赁-叉车租赁-6%', content: '临时叉车租用' },
        { label: '租赁-叉车租赁-9%', content: '临时叉车租用' },
        { label: '短驳费支出【其他】-公路-货运代理成本-6%', content: '公路货物运输代理费' },
        { label: '短驳费支出【其他】-铁路-货运代理成本-6%', content: '铁路货物运输代理费' },
        { label: '短驳费支出【其他】-运输费-9%', content: '暂时锁住不用' },
        { label: '短驳费支出【油卡费】-运输费-9%', content: '暂时锁住不用' },
        { label: '短驳费支出【现付+欠付+到付】-9%', content: '暂时锁住不用' }
      ],
      zzList: [
        { label: '运输成本-铁路运输-9%', content: '铁路、班列长途运输' },
        { label: '运输成本-铁路运输-6%', content: '铁路、班列长途装卸' },
        { label: '运输成本-海运运费-9%', content: '海陆运输' },
        { label: '运输成本-公路运费-9%', content: '供应商长途干线运输成本' },
        { label: '运输成本-铁路印花税-0%', content: '铁路局开票在备注中填写的印花税' },
        { label: '运输成本-印花税-0%', content: '铁路局开票在备注中填写的印花税' },
        { label: '运输成本-铁路代理费-6%', content: '供应商代理向铁路报计划、防护费、装卸费' },
        { label: '运输成本-海运代理费-6%', content: '海运经济代理服务费' },
        { label: '运输成本-快递费-6%', content: '物流辅助收派服务散货快递费' },
        { label: '运输成本-补差价-运输-9%', content: '付给司机一些临时打杂费，现场结清' },
        { label: '运输成本-货物保险-6%', content: '整车购买货物保险' },
        { label: '运输成本-压车费-9%', content: '司机到拉货地或送货地，因排队等待装卸货产生压车费' },
        { label: '运输成本-压车费-6%', content: '司机到拉货地或送货地，因排队等待装卸货产生压车费' },
        { label: '运输成本-放空费-6%', content: '下单后因货物未达到装货状态，司机空跑产生放空费' },
        { label: '装卸成本-吊机费-6%', content: '重货吊机费' },
        { label: '装卸成本-装卸费-6%', content: '货物装卸搬运成本' },
        { label: '装卸成本-临时用工成本费-6%', content: '现场临时需要帮忙搬运货物，请临时工费用' },
        { label: '装卸成本-临时叉车工-6%', content: '现场请临时叉车工费用' },
        { label: '装卸成本-加固费-6%', content: '铁路局车皮货物加固防护费' },
        { label: '装卸成本-提柜费-6%', content: '铁路局提取车皮，整箱装货' },
        { label: '装卸成本-掏箱费-6%', content: '铁路局对百货类物品进行掏箱检验是否违禁' },
        { label: '包装材料-耗材费-13%', content: '货物包装材料、防护用品，批量购买对公付款' },
        { label: '包装材料-耗材费-6%', content: '临时性紧急购买包装材料' },
        { label: '租赁-叉车租赁-13%', content: '现场临时需要帮忙搬运货物，请临时工费用' },
        { label: '租赁-叉车租赁-3%', content: '对公付款叉车租赁费' },
        { label: '租赁-叉车租赁-6%', content: '临时叉车租用' },
        { label: '运输成本-燃汽卡-9%', content: '网货平台支持支付燃汽卡，与运输费用一并开票' },
        { label: '运输成本-提货费-9%', content: '货物运输中，产生的提货费' },
        { label: '运输成本-送货费-9%', content: '货物运输中，产生的送货费' },
        { label: '运输成本-油卡-9%', content: '网货平台支持支付油卡，与运输费用一并开票' },
        { label: '运输成本-油卡-13%', content: '自购车辆中石油/中石化油卡加油' },
        { label: '中转费支出【油卡费】-9%', content: '暂时锁住不用' },
        { label: '中转费支出【其他】-9%', content: '暂时锁住不用' },
        { label: '中转费支出【现付+欠付+到付】-9%', content: '暂时锁住不用' }
      ],
      shList: [
        { label: '运输成本-公路运费-9%', content: '供应商长途干线运输成本' },
        { label: '运输成本-压车费-9%', content: '司机到拉货地或送货地，因排队等待装卸货产生压车费' },
        { label: '运输成本-压车费-6%', content: '司机到拉货地或送货地，因排队等待装卸货产生压车费' },
        { label: '运输成本-放空费-6%', content: '下单后因货物未达到装货状态，司机空跑产生放空费' },
        { label: '运输成本-补差价-运输-9%', content: '付给司机一些临时打杂费，现场结清' },
        { label: '装卸成本-装卸费-6%', content: '货物装卸搬运成本' },
        { label: '装卸成本-临时用工成本费-6%', content: '现场临时需要帮忙搬运货物，请临时工费用' },
        { label: '装卸成本-临时叉车工-6%', content: '现场请临时叉车工费用' },
        { label: '装卸成本-吊机费-6%', content: '重货吊机费' },
        { label: '包装材料-耗材费-13%', content: '货物包装材料、防护用品，批量购买对公付款' },
        { label: '包装材料-耗材费-6%', content: '临时性紧急购买包装材料' },
        { label: '租赁-叉车租赁-13%', content: '对公付款叉车租赁费' },
        { label: '租赁-叉车租赁-3%', content: '对公付款叉车租赁费' },
        { label: '租赁-叉车租赁-6%', content: '临时叉车租用' },
        { label: '运输成本-燃汽卡-9%', content: '网货平台支持支付燃汽卡，与运输费用一并开票' },
        { label: '运输成本-油卡-9%', content: '网货平台支持支付油卡，与运输费用一并开票' },
        { label: '运输成本-油卡-13%', content: '自购车辆中石油/中石化油卡加油' },
        { label: '送货支出【现付+欠付+到付】-9%', content: '暂时锁住不用' }
      ]
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    show (type) {
      this.describeVisible = true
      this.activeName = type
    },
    close () {
      this.describeVisible = false
    }
  },
  watch: {
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.describe_dialog_style {
  .el-dialog {
    border-radius: 12px;
    background: #f8f8f8;
    .my-label {
      background: #00bfbf;
      color: #FFFFFF;
      font-style: italic;
    }
    .my-content {
      background: #00bfbf;
      color: #ffff00;
    }
    .el-tabs--border-card {
      border: none;
      box-shadow: none;
    }
    .el-tabs__header {
      background-color: #f8f8f8;
      border-bottom: none;
      .el-tabs__item {
        border: 1px solid transparent;
        margin-top: -1px;
        color: #FFFFFF;
        background: linear-gradient(to top, rgb(25 174 241), rgb(95 199 246), rgb(129 211 248));
        box-shadow: 5px 5px 10px 0px rgba(25, 174, 241, 0.5);
        border-top-right-radius: 50px;
        width: 200px;
        text-align: center;
        margin-right: -50px;
        z-index: 91
      }
      .el-tabs__item:first-child {
        width: 160px;
        z-index: 92
      }
      .el-tabs__item:last-child {
        z-index: 90
      }
      .el-tabs__item.is-active {
        background: #FFFFFF;
        color: #0000ff;
      }
    }
    .el-tabs__content {
      padding: 0 0;
      max-height: 500px;
      overflow-y: auto;
    }
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-describe-dialog/index.vue"
}
</vue-filename-injector>
